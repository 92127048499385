import React from 'react'
import Images from "../../Assets/Images/index";

function HealthNet() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br />  <br /> <br />
                        <h1 class="fw-bold text-center fs-1 mt-2" style={{ color: "#FE7E00" }}>HealthNet</h1>
                        <p class="fs-5 text-justify">
                            Healthcare technology is moving so fast. From appointment booking to inventory management, billing, and laboratory management, everything is now controlled by hospital management software. It’s a great opportunity to utilize this innovative technology to reduce manual paper efforts and adopt the new paperless system. <br /> <br />
                            <img src={Images.Health12} alt=' image' class=" img-fluid  mb-3  w-100" />
                            To improve the healthcare industry, MassiveStar developed multifunctional hospital management software. With our <span className='fw-bold'>HealthNet </span> software, we automate several functions like Online Appointment Management System, Doctor Virtual Consultation Management, Patient’s health history, Medicine delivered, Billing Summary, Updated Information by CC and so on. Also, you could have your online E-prescription, Medicine reminder, Sleep reminder on that app. Our HealthNet is also integrated with several important modules and processes related to patients, which ensure full control of administrative staff, and medical professionals and also ensure timely patient care and quick diagnosis. Apart from this, we can also add new features and modules based on the client's requirements. <br /> <br />
                            Our hospital management system is web-based software and can be used from anywhere in the world. It comes with a nice user-friendly interface and is very easy to manage. No technical training is required, any non-technical person can use the software. Not to mention, our hospital management system is developed in a highly secured platform that is not easily hackable. We also make sure that Your data is stored in a secure place and free from unauthorized access. <br /> <br />

                        </p>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center ">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-start fs-3 " style={{ color: "#FE7E00" }}>Our main focus –</h1>
                                    i)	Verified doctor<br />
                                    ii) Doctor's Appointment <br />
                                    iii) Health History<br />
                                    iv) Fast support<br />
                                    v)	Medicine delivered in your door<br />
                                    vi)	Better consultation quality<br />
                                    vii) Medicine reminder<br />
                                </p>
                            </div>
                            <div className="col-6">
                                <img src={Images.Health} alt=' image' class=" img-fluid float-left me-3 mb-3  w-100" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <img src={Images.Health2} alt=' image' class=" img-fluid float-left me-3 mb-3  w-100" />
                            </div>
                            <div className="col-6 d-flex align-items-center ">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}>Mobile and Web Apps</h1>
                                    Your business needs to be where your customers are. You need to present them with the best experience as they engage with your healthcare business via their laptops and mobile phones. We have you covered with a custom branded experience for both your patients and providers on the Android, iOS and Web. <br /> <br />
                                </p>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-6">
                                <img src={Images.Health3} alt=' image' class=" img-fluid float-left me-3 mb-3  w-100" />
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}>Marketing Website</h1>
                                    A marketing website that is well integrated with all your healthcare services, is a critical part of your digital front-door strategy. <br /> <br />
                                    We make it easy - patients who reach you via Google are presented with one seamless flow – browse your website, choose a service, book an appointment or avail of the service, make an online payment and register with you. And in case you already have a marketing website in place, you can easily plug-in the patient portal into that. <br /> <br />
                                </p>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-6">
                                <img src={Images.Health2} alt=' image' class=" img-fluid float-left  mb-3  w-100" />
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}>Patient Portal</h1>
                                    Get an integrated patient portal available via both the mobile apps and web. It allows your patients to register and login, access all their medical records, download prescriptions and lab reports and avail the various services that your business offers. <br /> <br />
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center">
                                <img src={Images.Health4} alt=' image' class=" img-fluid float-left mb-3  w-100" />
                            </div>
                            <div className="col-6">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}> Notifications</h1>
                                    Timely and well-designed notifications are a crucial aspect of your digital customer engagement strategy. Notifications need to be omni-channel – email, sms and push notifications. They need to be timely and action oriented to ensure that patients receive the information at the right time and can take action. The HealthNet Virtual Practice provides you with an out-of-box notification system which also includes appointment reminder alerts that help reduce no-shows. <br /><br />
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <img src={Images.Health6} alt=' image' class=" img-fluid float-left me-3 mb-3  w-100" />
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}> Appointment Scheduling</h1>
                                    The HealthNet Virtual Practice comes integrated with a powerful clinic appointment scheduling software module complete with email/sms/push notifications to patients and providers. Exercise complete control over each doctor’s calendar and can define their services and timings, non-working days. You get a choice of Calendar and List views to confirm, reschedule and cancel patient appointment bookings. Automated appointment reminders are sent out to patients to ensure no-shows are kept down to a minimum. <br /> <br />
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 shadow-lg p-3 mb-5 bg-white rounded">
                                <img src={Images.Health7} alt=' image' class=" img-fluid float-left me-3 mb-3  w-100" />
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}> Updated Information Any time by CC</h1>
                                    1.At Call Center. <br />
                                    2.During any entry. <br />
                                    3.By Patient Himselfe. <br />

                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 shadow-lg p-3 mb-5 bg-white rounded">
                                <img src={Images.Health8} alt=' image' class=" img-fluid float-left  mb-3  w-100" />
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}> Doctors global network</h1>
                                    Global Doctors for Choice (GDC) creates opportunities for doctors to engage in local reproductive health and rights initiatives that affect policy change in their communities. We help physicians learn from each other, building a network for global change.<br /> <br />
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 shadow-lg p-3 mb-5 bg-white rounded">
                                <img src={Images.Health9} alt=' image' class=" img-fluid float-left  mb-3  w-100" />
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}> Connected with nearby healthNet center</h1>
                                    We are always connected with nearby healthNet center.<br /> <br />
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 shadow-lg p-3 mb-5 bg-white rounded">
                                <img src={Images.Health10} alt=' image' class=" img-fluid float-left  mb-3  w-100" />
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}>Consultations and Prescriptions</h1>
                                    You can access your past consultations and prescriptions at any time, and even get your medicine delivered<br /> <br />
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 shadow-lg p-3 mb-5 bg-white rounded">
                                <img src={Images.Health11} alt=' image' class=" img-fluid float-left  mb-3  w-100" />
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}>Consult live </h1>
                                    "Find one of the best Doctors to get instant medical advice and second opinion for your health problems. Ask doctors online and consult them through video, phone, or chat." Get started now! and get instant prescription and get instant prescription.<br /> <br />
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 shadow-lg p-3 mb-5 bg-white rounded">
                                <img src={Images.Health5} alt=' image' class=" img-fluid float-left  mb-3  w-100" />
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p class="fs-5 text-justify"><h1 class="fw-bold text-center fs-3 " style={{ color: "#FE7E00" }}> Online Payment</h1>
                                    Accepting payments from your customers is as easy as connecting your payment gateway account to your Virtual Practice. Set the pricing and taxes for each of your services/providers and the system handles the rest. <br /> <br />
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default HealthNet