import React, { useState } from 'react'
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import Images from "../../../Assets/Images/index";
import Sample from './Sample.pdf';
import Sample2 from './Sample2.pdf';


function Profile() {
    const [defaultPdfFile] = useState(Sample);
    const [defaultPdfFile2] = useState(Sample2);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <>
            <div className="container">
                <br /><br /><br />
                <div className="row">
                    <div class="col-md-12 col-sm-6">
                        <img src={Images.Sir} alt=' image' class="mt-4 img-fluid float-right ms-3 mb-3  w-25 " />
                        <p class="fs-5 text-justify mt-4">

                            <h1 class="fw-bold">SM. Mahabub Alam</h1>
                            Chief Executive Officer <br />
                            MassiveStar Studio Ltd. <br />
                            <span class="fw-bold">Career Summary:</span>
                            <ul>
                                <li><span class="fw-bold">Games development,</span>games project management and games design around 8 years [including 3 years Games project management with Electronic Arts (EA Sports) USA, 1 year games design with BAF.] </li>
                                <li>Conduct extensive games awareness and learning program at educational institute named <span class="fw-bold">“We Make Games” at 18 institute with 11,000 students.</span></li>
                                <li>Worked 25+ years in Technology (games, outsourcing, banking, ERP, health & RMG) and Education sector with 23+ years in senior and top most management position </li>
                                <li>Provide business leadership as Group CEO (Daffodil Group) to diversified business group comprising more than 800 people.</li>
                                <li>Provide technical leadership as CTO (EATL), Director Engineering (ReliSource) and head of technology (ISL).</li>
                                <li>Extensive experience in building and managing successful business in Technology, ICT, Education and Training, Management Consultancy, R&D, Software Development, Outsourcing and Customer Support organizations with a keen focus on business, management  process and engineering excellence. </li>
                                <li><span class="fw-bold">More than 25 years of Business development, Management, Sales & Marketing experience in international and local technology market. </span> </li>
                                <li>Involved in extensive change management and process re-engineering in the course of project development in large group of companies comprising more than 20,000 people</li>
                                <li>Acquired wide-ranging business development and project management experience from the largest multinational ICT solutions Company. </li>
                                <li>Managed and provided technical lead for many large enterprise projects including reengineering technology, Software and Hardware solutions.</li>
                                <li>Provide technical leadership of a project cost USD 10 million, “HCDP” (funded by Netherlands) with immense involvement in project management involving marketing, design, development, and implementation.</li>
                                <li>Self-starter with ample drive, initiative, dedication, and determination; immense experience with the starting up and successful business initiatives</li>
                                <li><span class="fw-bold"> Focusing towards large scale real entrepreneur development [guided by 3I and G2G (Good to Great)] in the country with a goal to produce 1000 real entrepreneur in the country by 2018.</span> </li>
                            </ul>
                            <span class="fw-bold">Educational Details:</span>
                            <ul>
                                <li>Master of Business Administration (MBA) from East West University, Dhaka</li>
                                <li>B.Sc. Engineering in Electrical & Electronic Engineering from BUET. </li>
                            </ul>
                            <span class="fw-bold">Mentioned In International Press and Report: </span>News at Deutsche Welle (DW) <br />
                            <a href="http://dw.de/p/1CN4G" class="text-primary"> Go to Report </a><br />
                            <span class="border">KPMG Report, Bangladesh Beckons, Published in January, 2012 <br /> <br />
                                “The specialized service offerings for our clients allow us to do high-end product and engineering projects, and we hire the best talent from Bangladeshi Universities for that” says S M Mahabub Alam, Director Engineering of Relisource  one of the largest niche engineering services company in Bangladesh.” For more www.kpmg.com/BD/en/.../UNITC%20KPMG%20Whitepaper.pdf <br /> <br />
                                Dutch Magazine, o u t s o u r c e m a g a z i n e, Published in October, 2011 <br /> <br />
                                [De werkmentaliteit is het problem niet, zo maakt de directeur van IT-bedrijf Relisource, Mahabub Alam, treffend duidelijk: “We have to fight and work hard to survive. It is a big struggle, but we are going up.”] For More www.gpic.nl/OM-Bangladesh.pdf <br /> <br />
                            </span>
                            <span class="fw-bold">International Training :</span>
                            Trained in Corporate Governance, Strategic business development, Management by MBO (Management by Objective) way, Sales & Marketing, Strategic Marketing, Strategic Management, Multimedia and Games Technology, Software Engineering, Project management, Quality Management (ISO), People Management, Intercultural Communication, AS/400 (from IBM), Network Technology, Software Technology from Netherlands, France, Malaysia, USA, Japan and India. <br /> <br />
                            <span class="fw-bold">People & Project Management, Business Development and Marketing with the following counties:</span>
                            USA, UK, Germany, France, Belgium, Turkey, Sweden, Switzerland, Denmark, Netherlands, Malaysia & India. <br /> <br />
                            <span class="fw-bold"> Skill Summery </span>
                            <ul>
                                <li>Business Domain: Gaming and Entertainment, Technology, Education, Pharmaceutical, Outsourcing software services in niche area like Embedded System, Animation and Mobile Application; Retail Banking, Cooperative Banking, Health Care Management (Health Network), Hospital Automation System, Composite Garments and Textile Industry, Supply chain management in Small & Medium Industry, Insurance, Petroleum distribution industry and e-Commerce solutions. </li>
                                <li>Strategic Business: Expert on Blue Ocean Strategy, Innovation by developing of BOS camp, Guidance for Good to Great, Marketing Strategy, Brand Development, International Marketing Strategy, Implementation of 3I (Intelligent, Integrated and International), Leadership and Salesmanship, Entrepreneurship and Life skill.    </li>
                                <li>People Management: 25+ years of people management experience from diversified culture, country, scale, position and mentality.</li>
                                <li>People and Organizational development; and coaching: Involved development of different level of people based on organizational need.  </li>
                                <li>Management Process Reengineering:  Management process reengineering around 15 companies. </li>
                                <li>Change Management: Involved change management last 10 years in 5 companies.  </li>
                                <li>Project Management: Around 35 small, large and medium project from home and abroad in 18 years. Among them around 10 are large integrated projects and 5 are large outsourcing project from USA. </li>
                                <li>Business Strategy, Consultancy and Management Solutions: Consultancy services has been provided to 25 (around) projects </li>
                                <li>Business and resource (Team) Development:  In all my ventures I spent a great deal of time in forming, developing, stimulating, and mentoring my team. I recruit the team members and train them towards the goal of establishing a specific system methodology which I have developed and tested. <br />
                                    An integral part of my responsibilities includes developing business strategies, understanding the market, segmentation, managing the marketing team, formulating corporate policies, and investment planning along with people management.</li>
                                <li>Appropriate Methodology Development: I spent 12+ years to formulate an appropriate methodology for project management and HR management that suites in the country</li>
                            </ul>
                            <span class="fw-bold"> Experience Details: </span>
                            <ul>
                                <li> <span class="fw-bold">From October 2012 to October 2013 : </span>Group CEO ,Daffodil Group.
                                    The group comprising of 18 business concern, focusing towards two major industries like Education and ICT. The major business units are Daffodil International University, DIIT, DIPTI, BSDI, DIC, DIS, Daffodil Computers Ltd, Daffodil Software Ltd, Daffodil Online Ltd etc. <br />
                                    The responsibility was to manage all concern of group, provide leadership to all directors and concern head, strategy development, strategic business, strategic management and strategic marketing.
                                </li>
                                <li> <span class="fw-bold">From Nov, 2006 to September 2012 : </span>Director of Business Development and Engineering <br />   ReliSource Technologies Ltd. <br /> USA based outsourcing software company (HQ Boston, USA). </li>
                                <li>Nov, 2008 to Nov, 2010 <br />Chief Technical Officer <br /> Ethics Advanced Technology Ltd. (EATL) </li>
                                <li>Nov, 2002 to Nov, 2006 <br />Head of Software <br /> Software Solutions Division <br /> Informations Solutions Ltd. (ISL)  <br />(A Genesis Technology Group company. One of the largest ICT group in Bangladesh </li>
                                <li><span class="fw-bold">Duration: July 1999 to Nov 2002</span><br />Education Consultant <br />Beximco System Ltd. <br /> A joint venture concern of NIIT and Beximco.</li>
                                <li>Duration: Aug 1997 to June 1999 <br /> Solutions Specialist   <br />(AS/400 and Network Solutions) </li>
                                <li>Thakral Information Systems (Pvt.) Ltd. <br />A Singapore-based 6-billion dollar company with presence in 34 countries. Thakral is the largest foreign invested ITC total solutions company in Bangladesh; represents ICT solutions based on IBM, HP, Cisco, Oracle, Lucent etc. I was a pioneering team member of Thakral in Bangladesh.</li>
                                <li>Duration: January 1997 to Aug, 1997  <br />Software Engineer <br /> Prime Computers Ltd.    <br />  A software solutions company.</li>
                                <li>Duration Aug 1992 to 1996: <br />   Solution Developer, Perspective Computers <br />An IT service company providing services for graphics design and programming</li>
                            </ul>
                            <span class="fw-bold">Games Project</span>
                            <ul>
                                <li>War71, total 8 years project started from 2014 and continued till 2021. Total 21 Episode in many platform, in many languages. After completion it will be the single largest history based games in the world. </li>
                                <li>Hatirjheel Dream Begins, the 1st commercially developed games in Bangladesh.</li>
                            </ul>
                            <span class="fw-bold">Book publication (Author & Editor)</span>
                            <ul>
                                <li>Books on Oracle 8i, MS-SQL Server 7.0, Java 2, C++, Visual Basic 6.0, Doctors’ SQL, Java Scripts & VB Scripts, DHTML. [Books published within 1999 to 2001]  </li>
                            </ul>
                            <span class="fw-bold">Presentation & Workshop</span>
                            <ul>
                                <li>Guest speaker at Islamic University of Technology (IUT), Shahjalal University of Science & Technology (SUST), United International University, Bangladesh University of Engineering and Technology (BUET), Dhaka University (DU), University of Liberal Arts of Bangladesh (ULAB), United International University Speaker at Science program in Bangladesh Radio.  </li>
                            </ul>
                            <span class="fw-bold">Enterprise Level Projects:</span>
                            <ul>
                                •	Health Care Development Project HCDP <br />
                                •	Dragon (Group) Sweaters Bangladesh Ltd. Reengineering and Computerization
                                •	Fakir Group re-engineering and Computerization project; <br />
                                •	Independent University Bangladesh Campus network solutions project;<br />
                                •	Chittagong City Corporation Automation Project;<br />
                                •	Jamuna Oil Company Ltd. Computerization Project;<br />
                                •	Meghna Petroleum Company computerization project;<br />
                                •	BEPZA computerization project.
                            </ul>
                            <span class="fw-bold">Banking Projects:</span>
                            <ul>
                                •	Rupali Bank Ltd, Human Resource Management project; <br />
                                •	South East Bank Ltd. (SEBL), Remittance Management project;<br />
                                •	BDBL (Shilpa Bank Ltd.), Branch banking and enterprise solutions project;<br />
                                •	Peoples Credit & Cooperative Ltd.  (PCCL) automation project;<br />
                                •	Chartered Credit & Cooperative Ltd.  (CCCL) Branch banking and automation project.
                            </ul>
                            <span class="fw-bold">SME Project Experience:</span>
                            <ul>
                                •	Bangabhaban Web Portal and File Tracking System, Bangabhabon <br />
                                •	Visitor Management System, System design and Project Management  <br />
                                •	GRI Insurance Incentive Management system <br />
                                •	Sales Management System, Clarinet Bangladesh (Pvt.) Ltd.<br />
                                •	Computerization of Modern Dental Clinic with HCMS.<br />
                                •	Computerization of Basak’s Clinic, Sylhet.<br />
                                •	Ibn Sina Spine Center Automation, Dhaka.<br />
                                •	Computerization of Noor Clinic, Dhaka.<br />
                                •	Computerization of Medi-Aid Diagnostic Center, Sylhet.<br />
                                •	Computerization of Rafiq’s Dental Care, Dhaka.<br />
                                •	IDS Fortune 400, Hospitality Software based on AS/400 <br />
                                •	IDS Fortune NT, Hospitality Software based on NT <br />
                                •	AS/400 based Insurance software system
                            </ul>
                            <span class="fw-bold">Corporate Training and consultancy:</span>
                            <ul>
                                •	Karnafuli Group <br />
                                •	HawarIT Ltd. <br />
                                •	Reve Systems<br />
                                •	ShehalaIT <br />
                                •	Bio-Pharma<br />
                                •	General Pharma<br />
                                •	Renata Ltd.<br />
                                •	Mostafa Group<br />
                                •	Apex Group<br />
                                •	Dragon Group<br />
                                •	Rupali Bank Ltd.<br />
                                •	Bangladesh Development Bank Ltd.<br />
                                •	Fakir Group<br />
                                •	CD Acrylic Ltd.<br />
                                •	Chittagong City Corporation<br />
                                •	Meghna Petroleum Ltd.<br />
                                •	Bangabhaban <br />
                                •	Jamuna Petroleum Ltd.<br />
                                •	International Medical College<br />
                                •	BSMMU<br />
                                •	BIRDEM Hospital<br />
                                •	Peoples Credit and Cooperative Ltd.<br />
                                •	Chartered Credit and Cooperative Ltd.<br />
                                •	Prime Life Insurance Ltd.<br />
                                •	Birds Group <br />
                                •	Duncan Group
                            </ul>
                            <span class="fw-bold">Country Visited:</span>
                            USA, UK, Switzerland, Germany, France, Belgium, Turkey, Sweden, Denmark, Netherlands, Thailand, Malaysia & India.
                        </p>
                    </div>
                </div>

                <span class="fs-3 fw-bold">Resource</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile2 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile2}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={Sample2}
                    download="ESM-Mahabub-Alam-short-profile-18-12-14-FO">
                    Download
                </a><br></br><br></br>
            </div>
        </>
    )
}

export default Profile
