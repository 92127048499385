import React, { useState } from 'react';
import Images from "../../Assets/Images/index";
import { Viewer } from '@react-pdf-viewer/core'; // install this library
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; // install this library
import IUBAT from './IUBAT.pdf';
import IUBAT2 from './IUBAT2.pdf';

function BrainEngineeringIUBAT() {
    const [defaultPdfFile] = useState(IUBAT);
    const [defaultPdfFile2] = useState(IUBAT2);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br /> <br /> <br />
                        <div class="tab-content">
                            <div class="tab-pane container active" id="home">
                                <h2 className="text-center mt-2" style={{ color: "#FE7E00" }}> 1st National Conference on Brain Engineering</h2>
                                <h3 className="text-center">“IUBAT introduced Brain engineering course in the curriculum.” </h3>
                                <img src={Images.IUBAT1} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <p class="fs-5 text-justify">
                                    On Friday, 5th October, 2018 IUBAT Brain Engineering Research Group (IBERG) and MassiveStar Studio Ltd jointly organized the very first national HR conference on Brain Engineering. The daylong session was held in IUBAT campus in Uttara, Dhaka. The Vice Chancellor of IUBAT, Prof. Dr. Abdur Rab, welcomed the distinguished guests. The key speaker was Mr. SM. Mahabub Alam, Managing Director, MassiveStar Studio Ltd .& President, Brain Engineering Forum. He captivated an audience of HR directors and general managers from 100 over reputed organizations including S Alam Group, Nitol Motors, BSHRM etc. Mr. Alam disseminated knowledge of Brain Science, Neuroplasticity, Neuromanagement and its impact on management processes, Brain Engineering at G2G (Good to Great), the Science of Perception and Biology of Management. Prof. Dr. Bishwajit Saha, Group Leader, IBERG and Coordinator, EEE Department, IUBAT was the convener and welcome addresses were delivered by Prof. Dr. Mohammed Tareque Aziz, Dean, College of Business Administration, IUBAT and Prof. Dr. Mostofa Akbar, Head, Dept. of CSE, BUET. In addition, an introduction to the research initiatives in interactive software development for learning, design of biocompatible prosthesis and future prospects of IBERG was presented by Dr. Muammer Din Arif, IBERG member and IUBAT faculty member in ME. <br /> <br />
                                    The presentations were followed by a panel discussion chaired by Prof. Dr. Abdur Rab and included eminent bureaucrats, vice chancellors, professors, researchers and senior corporate executives: Prof. Dr. Abdul Hannan Chowdhury, VC, Primeasia University;Prof. Dr. Matiur Rahman, VC, Presidency University; Prof. Dr. NazmulKarim Chowdhury, VC, Fareast Int’l University;Md. Saiful Islam, Managing Director, Add’l Secretary, Bangladesh Communication Satellite Company Ltd.; Mr. Susanta Kumer Saha, Add’l Secretary, Ministry of Finance; Md. Mustafizur Rahman, Add’l Secretary, Project Director, a2i Programme; Md. Lt. Col. Abu Ayub, Chairman, Bangladesh University of Professionals;M. Zulfiquar Hussain, Managing Director, Grownexcel Ltd.; and KaziRakibuddin Ahmed, Chief, Human Resources Division, KAFCO.  <br /> <br />
                                    The program was concluded with a crestgiving ceremony and promise of collaboration among the universities, government organizations, corporate bodies and IBERG Researchers were present the event. <br />
                                </p>
                                <img src={Images.IUBAT2} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.P2} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.P3} alt=' image' class="img-fluid mt-3 mb-3  w-100" />

                                <img src={Images.P5} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <h2 className="text-center">Plenary session </h2>
                                <img src={Images.IUBAT3} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.P4} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.IUBAT4} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <h2 className="text-center">welcome session </h2>

                                <img src={Images.IUBAT6} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col">
                        <br /> <br />
                        <h2 className="mb-3 text-bold text-center">We are in Media </h2>
                        {defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile2}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div>
                <div className="row ">
                    <div className="col">
                        <br /> <br />
                        {defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div>
                <br /> <br /> <br /> <br /> <br />

            </div >
        </>
    )
}

export default BrainEngineeringIUBAT