import React from "react";
import "./Navbar.css";
import Images from "../../Assets/Images/index";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <React.Fragment>
      <div class="container">
        <div class="row">
          <section class="TopNav ">
            <nav class="navbar fixed-top  navbar-dark navbar-expand-md  bg-dark  text-white shadow">
              <div class="container">
                <a class="navbar-brand" href="http://www.massivestarstudio.com/">
                  <img src={Images.Logo} class="logo" alt="logo" />
                </a>
                <button
                  class="navbar-toggler  "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#main-nav"
                  aria-controls="main-nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon "></span>
                </button>
                <div class="collapse navbar-collapse justify-content-end " id="main-nav" >
                  <ul id="menu" class="menu navbar-nav ">
                    <li>
                      <Link class="nav-link active" to="/">
                        Home
                      </Link>
                    </li>
                    <li class="nav-item dropdown">
                      <Link class="nav-link dropdown-toggle" to="/about" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        AboutUs
                      </Link>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><Link class="dropdown-item" to="/about">About Us</Link></li>
                        <li><Link class="dropdown-item" to="/about/our-history">Our History</Link></li>

                      </ul>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Products
                      </a>
                      <ul class="dropdown-menu mega-menu " aria-labelledby="navbarDropdown">
                        <li class="row">
                          <li class="col-lg-3  col-sm-3">
                            <h5><strong class="sub-menu-heading">HealthNet</strong></h5>
                            <p><Link to="/products/health-net">Health Net</Link></p>


                          </li>
                          <li class="col-lg-3  col-sm-3">
                            <h5><strong class="sub-menu-heading">Games, VR & Animation</strong></h5>
                            <p><Link to="/products/game">Games, VR, Animation</Link></p>


                          </li>
                          <li class="col-lg-3 col-sm-3">
                            <h5><strong class="sub-menu-heading">FoodNet</strong></h5>
                            <p><Link to="/products/food-net">Food Net</Link></p>

                          </li>
                          <li class="col-md-3 col-sm-3">
                            <h5><strong class="sub-menu-heading">EduNet</strong></h5>
                            <p><Link to="/products/edu-net">Edu Net</Link></p>


                          </li>
                        </li>
                        <li class="row">
                          <li class="col-md-3 col-sm-3">
                            <h5><p><strong class="sub-menu-heading">RetailerNet</strong></p></h5>
                            <p><Link to="/products/retailer-net">Retailer Net</Link></p>


                          </li>
                          <li class="col-md-3 col-sm-3">
                            <h5><p><strong class="sub-menu-heading">ERP for SME & Manufacturing Unit</strong></p></h5>
                            <p><Link to="/products/ERP">ERP for SME & Manufacturing Unit</Link></p>


                          </li>
                          <li class="col-md-3  col-sm-3">
                            <h5><p><strong class="sub-menu-heading">Customized Software</strong></p></h5>
                            <p><Link to="/products/customized-software">Customized Software</Link></p>


                          </li>
                          <li class="col-md-3  col-sm-3">
                            <h5><p><strong class="sub-menu-heading">Brain Engineering & AI</strong></p></h5>
                            <p><Link to="/brain-engineering">Brain Engineering</Link></p>
                          </li>
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        CorporateTraining
                      </a>
                      <ul class="dropdown-menu " aria-labelledby="navbarDropdown">
                        <li><Link class="dropdown-item" to="/corporate-training/who-we-are">Who We Are</Link></li>
                        <li><Link class="dropdown-item" to="/corporate-training/training-details">Training Details</Link></li>
                        {/* <li><Link class="dropdown-item" to="/corporate-training/registration">Registration</Link></li> */}
                        <li><Link class="dropdown-item" to="/corporate-training/training-gallery">Training Gallery</Link></li>
                        <li class="dropend">
                          <a class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"  >Consultancy</a>
                          <ul class="dropdown-menu shadow">
                            <li><Link class="dropdown-item" to="/corporate-training/consultancy">Consultancy</Link></li>
                            <li><Link class="dropdown-item" to="/corporate-training/academic-consultancy">Academic Consultancy</Link></li>
                            <li><Link class="dropdown-item" to="/corporate-training/business-process">Business Process</Link></li>
                            <li><Link class="dropdown-item" to="/corporate-training/it-transformation">IT Transformation</Link></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown">
                      <Link class="nav-link dropdown-toggle" to="/brain-engineering" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        BrainEngineering
                      </Link>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><Link class="dropdown-item" to="/brain-engineering">BrainEngineering(Homepage)</Link></li>
                        <li><Link class="dropdown-item" to="/brain-engineering2">1st National Conference on Brain Engineering</Link></li>
                        <li><Link class="dropdown-item" to="/brain-engineering-aiub">1st National Conference on Brain Engineering(AIUB)</Link></li>
                        <li><Link class="dropdown-item" to="/brain-engineering-iubat">1st National Conference on Brain Engineering(IUBAT)</Link></li>
                        <li><Link class="dropdown-item" to="/brain-engineering-all">1st National Conference on Brain Engineering(ALL Program)</Link></li>
                        <li><Link class="dropdown-item" to="/brain-engineering-research">Research</Link></li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown">
                      <Link class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        EGIP
                      </Link>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><Link class="dropdown-item" to="/EGIP/EGIP-details">EGIP Details</Link></li>
                        <li><Link class="dropdown-item" to="/EGIP/day-long-camp">Day Long Camp</Link></li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown">
                      <Link class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Games
                      </Link>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><Link class="dropdown-item" to="/Games/games">Games</Link></li>
                        <li><Link class="dropdown-item" to="/Games/we-make-games">We Make Games</Link></li>
                        <li><Link class="dropdown-item" to="/Games/mobile-game">Mobile Games</Link></li>
                        <li>
                          <Link class="dropdown-item" >PC Games >></Link>
                          <ul class="dropdown-menu dropdown-submenu ">
                            <li><Link class="dropdown-item" to="/Games/juddho71">Massive Juddho71</Link></li>
                            <li><Link class="dropdown-item" to="/Games/hatirjheel-dream-begins">Hatirjheel Dream Begins</Link></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link class="nav-link" to="/media">
                        Media
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}
