import React from 'react'
import Images from "../../Assets/Images/index";

function EGIP() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br></br><br></br><br></br>
                        <img src={Images.EGIP} alt=' image' class="mt-4 img-fluid float-left me-5 mb-3  w-50" />
                        <p class="fs-5 text-justify mt-4">
                            <span class="fw-bold text-break">Entrepreneur Guidance and Implementation Program</span><br></br>
                            EGIP is derived from a Blue Ocean concept of MABT. EGIP is an initiative to develop Entrepreneur through Skills Development Training and giving opportunity to become self dependent. Through Entrepreneur Development Program and Skill Development Training our people are inspired to put their skills and interests to work creating sustained impact in communities around the world.<br></br>
                            Under the program we will train the talent to help address the need for skills that open doors to self employment. The initiative harnesses the powerful combination of knowledge development support and infrastructural support so that they can contribute in National Economy. Entrepreneur Development Program is our global corporate citizenship initiative, which focuses on advancing employment and entrepreneurship opportunities in both mature and emerging markets.<br></br>

                            <ul>
                                <li><span class="fw-bold">Motivation</span> is a psychological feature that arouses an organism to act towards a desired goal and elicits, controls, and sustains certain goal-directed behaviors. It can be considered a driving force; a psychological one that compels or reinforces an action toward a desired goal. For example, hunger is a motivation that elicits a desire to eat. Motivation is the purpose or psychological cause of an action. Only highly motivated person can be an entrepreneur. They can take risk, initiate and also exercise the initiative by organizing a venture to take benefit of an opportunity and, as decision maker, decides what, how, and how much of a goods or service will be produced.</li>
                                <li>An<span class="fw-bold">entrepreneu</span>  must need proper guidance. If a person does not get proper guidance they cannot transform themselves as an entrepreneur. We provide guidance to help a person for entrepreneurship.</li>
                                <li>An entrepreneur should be based on knowledge. That should be practical knowledge. Bookies knowledge gives you marks but practical knowledge will give you success. Here a person will acquire knowledge, gather knowledge & exercise knowledge.</li>
                                <li>An entrepreneur must be good team S/he must have all team work capability by which they can get maximum result by giving minimum effort. Team work helps to be sustainable, flexible and reliable.</li>
                                <li>An entrepreneur must have techniques to generate new ideas. Here we help to learn the strategy by which a person can generate feasible, measurable, new idea.</li>
                                <li>Entrepreneur Guidance Program Focus on,</li>
                                <li> 3I</li>
                                <ul>
                                    <li>Intelligent</li>
                                    <li>Integrated</li>
                                    <li>International</li>
                                </ul>
                                <li>Good to Great (G2G)</li>
                                <ul>
                                    <li>Disciplined People</li>
                                    <li>Disciplined Thought</li>
                                    <li>Disciplined Action</li>
                                </ul>
                            </ul>
                            <span class="fw-bold">Entrepreneur Guidance Program comprises with,</span><br></br>
                            Entrepreneur Guidance Program is for those people who want to be a self starter. In the program we provide continuous guide, monitor & supervise to make people successful entrepreneur.<br></br>
                            Required Knowledge for Entrepreneurship <br />
                            Basic Knowledge AreaAnalytical SkillKnowledge of Ethics <br />   SenseWisdom <br />
                            Applied Knowledge AreaStrategic Business/ MarketingMarketing & Sales <br />
                            Business Ethics<br />
                            Consumer Behavior<br />
                            Management Knowledge<br />
                            Organizational Behavior<br />
                            How to manage HR<br />
                            Business Accounting/ Finance<br />
                            Micro/ Macro Economics<br />
                            Reasoning Analysis<br /><br />
                            <span class="fw-bold">What a person can get from the program</span><br />
                            <ul>
                                <li>Outstanding presentation skill; </li>
                                <li>Proper guidance by that a person will think that he can become an Entrepreneur within a year under the program;</li>
                                <li>Starting a new business face plenty of constrains; we will help to overcome all constrain strategically;</li>
                                <li>Get practical knowledge rather than bookish knowledge;</li>
                                <li>Get knowledge from environment;</li>
                                <li>Biggest platform of entrepreneurs.</li>
                            </ul>
                            <span class="fw-bold">Outcome of the program</span><br />
                            <ul>
                                <li>Individuals can evaluate and think themselves as a self starter;</li>
                                <li>Be an entrepreneur;</li>
                                <li>Right Professional/ Complete Man or Woman. It means Entrepreneur next hand;</li>
                                <li>Courage/ Experience;</li>
                                <li>Create Platform;</li>
                                <li>We will provide techniques how you can provide job.</li>
                            </ul>
                            <span class="fw-bold">Who can join in the Program</span><br />
                            <ul>
                                <li>Students in any level.</li>
                                <li>Jobholder dreaming to start his own organization.</li>
                                <li>Graduate</li>
                            </ul>
                            <span class="fw-bold">Prerequisites to join in the program</span><br />
                            <ul>
                                <li>Business mentality;</li>
                                <li>Who believe “knowledge is my power”;</li>
                                <li>Team work mentality;</li>
                                <li>Have ability to work with innovative idea and implementation;</li>
                            </ul>
                        </p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EGIP