import React from 'react'
import Images from "../../../Assets/Images/index";


function Consultancy() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br></br><br></br>
                        <h1 class=" text-center mt-4 mb-2" style={{ color: "#FE7E00" }}>CONSULTING</h1>
                        <p class="fs-5 text-justify">
                            <span class="fw-bold ">Consulting Overview</span><br></br>
                            At Consulting, our goals are to help you reach your business targets faster and to increase your competitiveness. Our expert Business & IT Consulting Services help you make changes quickly, so you can satisfy customers and easily adapt to new requirements.<br></br>
                            We can help you:<br></br>
                            <ul> <li>Address gaps between business needs and IT capabilities;</li>
                                <li>Developing High Performance Managers and Process Improvement Tools & Techniques;</li>
                                <li>Guidance for Good to Great (G2G); </li>
                                <li>Entrepreneur Guidance and Implementation Program (EGIP);</li>
                                <li>Simply Effective Efficient Management (SEEM);</li>
                                <li>Human Resource Policy (HRP);</li>
                                <li>Blue Ocean Strategy (BOS);</li>
                                <li>Salesmanship through Attitude (HR training);</li>
                                <li>Brand Promotion Consultancy; </li>
                                <li>Defining Strategy in Business.</li>
                            </ul>
                            Count on us for expert guidance along all phases of the transformation process enabled by our proven products, methodologies and best practices.<br></br>
                            Meet your unique business requirements with a comprehensive, customizable package of Business & IT Consulting Services.
                            <h2 class="text-center  fs-2 mt-4">Consulting at ReveSystem</h2><br></br>
                            <img src={Images.C1} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                            <img src={Images.C2} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Consultancy