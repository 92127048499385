import './TrainingDetails.css';
import React, { useState } from 'react'
import { Viewer } from '@react-pdf-viewer/core'; // install this library
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; // install this library
import Images from "../../../Assets/Images/index";
import Sample from './Sample.pdf';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';


function TrainingDetails() {
    const [defaultPdfFile] = useState(Sample);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    return (
        <>
            <div className="container ">
                <div className="row">
                    <div class="col">
                        <br /> <br />
                        <h1 class="  mt-4 mb-2" style={{ color: "#FE7E00" }}>Corporate Training</h1>
                        {/* <h2 class="fs-2 text-center fw-bold mb-0 pb-0">Message from the CEO</h2> */}
                        <img src={Images.Sir3} alt=' image' class="rounded img-fluid float-center mx-auto d-block  mt-2 mb-3  w-25 " />
                        <p class="fs-5 text-justify">
                            Greetings!! from MassiveStar. I would like to introduce you with a new form of technical and corporate training that deals with science of performance and productivity enhancement through attitude. <br /> <br />
                            We all are preparing our team to reach on top, build capacity and gain excellence that will ensure our deserved goal. That is why we must concern about “Performance Enhancement, Increase Productivity” and “Attitude Improvement” to ensure Three-Sixty degree preparation for our engineering, marketing, technical support and management team.<br /> <br />
                            MassiveStar is focusing towards three sectors (1) Consulting and Corporate Training, (2) Technology and (3) Computer Video Games development. In corporate training area we are introducing ROS (Research Outcome Session) and NLP (Neuro Linguistic Programming) which is Blue Ocean methodology in consulting and corporate training.<br /> <br />
                            In Technology area we provide ERP solutions, we are distributing IFS in Bangladesh. In Games, we have launched 1st commercially developed computer video games series in Bangladesh named Hatirjheel Dream Begins. Please have a look at a news feature published in Deutsche Welle (DW) .<br /> <br />
                            Alhamdulillah, we have completed some large training event already. All employees of Reve Systems, the largest software company in Bangladesh in terms of revenue and global presence, already completed our “Attitude” training; many others like Apex Group, Karnaphuli Group, Renata, Biopharma, General Pharma, HawarIT and ShehalaIT are running now. More than 800 engineers, marketing personnel, team leads, managers, general managers, directors, ED, CEO, DMD and MD already completed “Enhance Performance Through Attitude” (EPTA) training.<br /> <br />
                            EPTA is a kind of brain exercise that creates a demand of improvement from inner side of a person. The whole process refreshes a person with knowledge and motivation, ultimate effect is enhance productivity and performance. For corporate training we follow NLP (Neuro-Linguistic Programming) method which is renowned worldwide and completely new in Bangladesh. This Research Outcome Session (ROS) is not just a motivational training rather it is a package solution for enhance performance and productivity.<br /> <br />
                            SM Mahabub Alam<br></br> CEO<br></br> <br />
                        </p>
                        <Link to="/corporate-training/training-details/profile" class="btn btn-primary btn-lg mt-2" role="button" aria-pressed="true">See More Details</Link><br /><br />
                        <span class="fw-bold fs-2">Our Other Mentor Products (consultancy and Training) are:</span>
                        <p class="fs-5 text-justify">
                            <ol>
                                <li>Enhance performance and skill for professionals through Attitude</li>
                                <li>Corporate International Training(Customized training for corporate);</li>
                                <li>Guidance for G2G (Consultancy and Training);</li>
                                <li>Defining Strategy in Business (Consultancy and Training);</li>
                                <li>“Simple, Effective, and Efficient Business Management (Consultancy)”;</li>
                                <li>Brand Promotion Consultancy;</li>
                                <li>Human Resource Policy Development (Consultancy);</li>
                                <li>Blue Ocean Strategy (BOS) camp (Customized offer for top management only);</li>
                                <li>Business Strategy development;</li>
                                <li>International Marketing and Marketing Strategy.</li>
                            </ol>
                        </p>
                    </div>
                </div>

                <div className="row ">
                    <div className="col">
                        <br /> <br /> <br />
                        <p class="fst-italic fs-4 text-center">Special training session on “NLP and Brain Engineering to Develop Level-5 Leadership in Management” at Cox’s Bazar</p>
                        {defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br><br></br><br></br><br></br> <br /><br /> <br /><br />
                <a class="btn btn-danger" role="button" href={Sample}
                    download="Enhance-Performance-through-Attitude-Coxs-Bazar-20th-and-21st-Feb-15-Top-Management-Gn">
                    Download
                </a><br></br><br></br>

            </div>
            <div className="container">
                <h1 class=" text-center mt-5 mb-4 " style={{ color: "#FE7E00" }}>Our clients talk for us</h1>
                <div className="row player-wrapper">
                    <div class="col-lg-6 col-md-12  ">
                        <ReactPlayer className="react-player" url="https://youtu.be/MqqhQCT2p0A?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12 mt-md-2 mt-lg-0">
                        <ReactPlayer className="react-player" url="https://youtu.be/hht-lbxTEVg?" width='100%' />
                    </div>
                </div>
                <div className="row">
                    <div class="col">
                        <p class="fs-5 text-justify">
                            It is a different type of training session which is useful and may change any one’s life. New thought in my life. The session on NLP is required for every person if possible.<br></br>
                            Kazi md. Ehasanuzzaman<br></br>
                            Sr. AVP<br></br>
                            Southeast Bank Limited.<br></br><br></br>

                            Learned many things about attitude which is planning an important role or team motivated. Hope it affects my professional life as wall. I feel a very positively attitude now. Very good session, I wish very very success of NLP, in my company.<br></br>
                            Dr. A.Haque<br></br>
                            Sr. Manager, R&D<br></br><br></br>

                            It was good and I am expecting more activities in between lecture.This workshop is effective for my life, I have change mentality and free ling encouraged to perform better.<br></br>
                            MD. Enamul Kabir<br></br>
                            GM, CRN Retail<br></br><br></br>

                            Very good as it help me to give better understanding of lot of thing like NLP, Attitude, Dream, Believe, Brain etc.<br></br>
                            Md. Sohel Hpwlader<br></br>
                            NSM<br></br><br></br>

                            Excellent session helped me to set my believe. Correct my attitude to increase performance as a team. Activate brain, increase activity to perform in a PMA manner and work as a team to achieve organizational goal. Increase my knowledge.<br></br>
                            Md. Monir Uzzaman<br></br>
                            GM<br></br>
                            Karnaphuli Group<br></br><br></br>

                            It is a nice session, change the view of thinking. Everything was good wish to attend this type of session in future.<br></br>
                            Md.Ashraful Islam Rony<br></br>
                            Tech.HRM<br></br>
                            Reve Systems<br></br><br></br>



                            We have learned many things here. We have to believe ourself. We learned about Dream, how to build up our attitude positively. We also learned to be loyal and satisfiy with our organization to success in future.<br></br>
                            Sajjad Hussain<br></br>
                            Asst.Manager( Admin & Accounts)<br></br>
                            ShehalaIT<br></br><br></br>



                            Very nice interactive. It helpful for daily work.<br></br>
                            Md.Abu Taher<br></br>
                            Sr.DSM<br></br>
                            Reneta Pharmaceuticals LTD.<br></br><br></br>

                            This session is fantastic one. A lot of think I learned from this session which will help me to improve professional career and professional dream.<br></br>
                            Mohammad Babar<br></br>
                            Head of Infrastructure<br></br>
                            Business Automation LTD<br></br><br></br>



                            This training session is very helpful for sells and marketing. It is enjoyable too.<br></br>
                            Md.Obaidul Islam<br></br>
                            DSM<br></br>
                            Reneta Pharmaceuticals LTD.<br></br><br></br>

                            This training will undoubtedly improve my thought process and will change my mind setup. It will help me to reduce my negative behavior. I feel confident at the end of the day.<br></br>
                            Sahriar mahmud<br></br>
                            Karnaphuli Group<br></br><br></br>



                            This is a good session. It is necessary to do such session within after three months for recharge employees.<br></br>
                            Md. Maimul Islam<br></br>
                            Manager,Finance & HR<br></br>
                            HawarIT<br></br><br></br>



                            This session is too good.Really it is a better for create dream.<br></br>
                            Sohana Rahman<br></br>
                            Senior Executive<br></br>
                            Business Automation LTD.<br></br><br></br>



                            I got good thinking attitude. I have learned the process of good performance at my office. Excellent Session.<br></br>
                            Md.Kamruzzaman<br></br>
                            Asst.Manager(Creative)<br></br>
                            ShehalaIT<br></br><br></br>



                            I am very proud to get this session which will help me to be successful my life.<br></br>
                            Kh.Ali.Khalid<br></br>
                            Sr.DSM<br></br>
                            Reneta Pharmaceuticals LTD.<br></br><br></br>

                            The session was very good and learned lot of thinks which will be needful for our current job. I got dream,attitude and skill of team build up. I learned how to enhance performance and enhance leadership.<br></br>
                            Sontosh Kumar Roy<br></br>
                            DGM<br></br>
                            Karnaphuli Group<br></br><br></br>



                            The session helped me to understand few things in a new way. This is a great session. We have learned many things like how to behave with others, how to show positive attitude to all and how positive attitude help to enhance outcome.<br></br>
                            Nurnabi<br></br>
                            Reve System<br></br><br></br>



                            Actually it was a excellent program. Because from this session I learned how to enhance performance by using attitude and to make a better team.<br></br>
                            Md Aminul Hasan sagor<br></br>
                            Karnaphuli Group<br></br><br></br>



                            Motivation for the team members set personal goal. I Learned how to improve anger management and capacity build up. I Learned how to enhance problem solving skill,showing confidence efficiently, time management, encouraging other people for positive behavior. I got answer all of my question from the day on Enhance performance and productivity through attitude.<br></br>
                            Md.Nur-A-Alam<br></br>
                            Asst.Manager Project Operation<br></br>
                            Business Automation LTD.<br></br><br></br>



                            I can make my dream visible again from now on. I had a dream,but it become had fade last few months. It was good and I take my dream with PMA with me.<br></br>
                            Shekh Shamsuzzaman<br></br>
                            Sr.Software Engineer<br></br>
                            HawarIT<br></br><br></br>



                            This was a good training session for all of us. We learn a lot of things from this session. Very much effective and resourceful. It Would have been discussed about the way to practice the attitude development,meditation. If we organize same thing in future it will help a lot for us.<br></br>
                            Khatib Shahnawaz<br></br>
                            Karnaphuli Group<br></br><br></br>



                            This training is very much essential for every people those who work in sells organization.The session was excellent.
                            Md.Habibur Rahman<br></br>
                            Area Manager<br></br>
                            General Pharmaceutical LTD.<br></br><br></br>

                            I understand the development process of attitude amongst team member. I learned factors affecting team building. It will be more effective for more user participation through team activity in the training session would have been in corporates.<br></br>
                            Imam-H-Shahriar<br></br>
                            GM<br></br>
                            karnapahuli Group<br></br><br></br>


                            The session is very nice and i think that the session will help me to improve me and my team as well as my organization I learned how to improve myself as a positive mental attitude person and my team member also. I learned how to execute my dream.<br></br>
                            Md.Ashrafuzzaman<br></br>
                            Head Of Application<br></br>
                            Business Automation LTD.<br></br><br></br>

                            It seems to me todays session will be help to enhance performance through attitude and I think it is better for a company. This session was good. Hope we will get another helpful training session in future again for 2-3 days long not in a single day.<br></br>
                            Md. Zahid Hossain Khan<br></br>
                            Karnaphuli Group<br></br><br></br>


                            Nice Presentation.I want this workshop in the future with my colleague.I learn how to be a positive mental attitude person.<br></br>
                            Md.Shafiul Haque<br></br>
                            Area Manager<br></br>
                            General Pharmaceutical LTD.<br></br><br></br>

                            I learned how can make my dream success. It was Excellent because I learned how to make a Positive Mental Attitude.<br></br>
                            Md.Masoqur Rahman Khan<br></br>
                            Asst.System Admin<br></br>
                            ShehalaIT<br></br><br></br>

                            This session has definitely changed my way of thinking and learned a lot about positive thinking.<br></br>
                            Reve System<br></br><br></br>

                            Training is the most important job for professional and personal life. Without learning nobody cannot success in his life . I think the session is very good.<br></br>
                            M.C.Sutradhar<br></br>
                            Manager,Finance & HR<br></br>
                            HawarIT<br></br><br></br>

                            I think this is a very special training session on enhance salesmanship through attitude. I learned how to change attitude. I believe that the session changed my life.<br></br>
                            Md.Mahmudur Rahman<br></br>
                            Area Manager<br></br>
                            General Pharmaceutical LTD.<br></br><br></br>

                            I spent my one of the best learning day. Learned lot of things. I found positive mental attitude is the key to execute my dream.<br></br>
                            Samim Reza<br></br>
                            Reve System<br></br><br></br>


                            The session was excellent because I got a lot of knowledge, attitude, dream, believe. I think it should held after three months again. I will change my mind. Thanks a lot for good speech.<br></br>
                            Md.Zillur Rahman<br></br>
                            General Pharmaceutical LTD.<br></br><br></br>

                            It is a great day. I have learn a lot of things which will help me in future life. I want to attend more session of you if I get chance.<br></br>
                            Shariful Islam<br></br>
                            Reve System<br></br><br></br>



                            Good and informative thought. Motivation and some important knowledge about attitude are excellent. Enjoyable session. It will help me in large scale in career. Got some career insight that was unknown before.<br></br>
                            Sajid Hasan<br></br>
                            Reve System<br></br><br></br>

                            The session inspired us to dream and taught us an approach to make the opportunity impossible dream true. This workshop changed the thought process of mine. I wish I had this earlier. How meditation is important in life to enhance performance.<br></br>
                            Saugatha Das<br></br>
                            Reve System<br></br><br></br>

                            This session was awesome, never thought attitude is so important in life. It will help us a lot to achieve my desired dream. Overall a good day. Learn some good things that will help in future.<br></br>
                            Zaber Ahmed<br></br>
                            Reve System<br></br><br></br>

                            Very good, Learn many things about job life. Good realization about attitude and management. Understand the power of meditation. Excellent. Very much informative session for upgrading my professional and personal life.<br></br>
                            Musrat Naser<br></br>
                            Reve System<br></br><br></br>

                            Understand clearly how attitude will multiply our performance. Understand about team work, SWOT analysis, meditation,PMA(Positive mental attitude). Very helpful in family and professional life.<br></br>
                            Shameem Hyder<br></br>
                            Reve System<br></br><br></br>

                            Very good and fulfilled the expectation. I am felling that I have achieved something morally beneficial.vI liked the session a lot. Hope it will help me to build up the PMA and will help to improve my performance and my team as well.<br></br>
                            Arafat al Mahmud<br></br>
                            Reve System<br></br><br></br>

                            Contact For Details<br></br>
                            +8802-9028939, +880-1673412422, +880-4475098890, +880-4477970980<br></br>
                            Email : marketing@massivestarstudio.com

                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingDetails