const Images = {

    Logo: require('./Logo.png'),
    AboutUs: require('./AboutUs.jpg'),
    // Slider
    Health: require('./Health.jpg'),
    Health2: require('./Health2.jpg'),
    Health3: require('./Health3.jpg'),
    Health4: require('./Health4.jpg'),
    Health5: require('./Health5.jpg'),
    Health6: require('./Health6.jpg'),
    Health7: require('./Health7.jpg'),
    Health8: require('./Verified.jpg'),
    Health9: require('./Health9.jpg'),
    Health10: require('./Health10.jpg'),
    Health11: require('./Health11.jpg'),
    Health12: require('./Health12.jpg'),

    VR: require('./VR.jpg'),
    FoodNet2: require('./FoodNet2.jpg'),
    RMS: require('./RMS.png'),
    RMS2: require('./RMS2.png'),
    RMS3: require('./RMS3.png'),
    EduNet: require('./EduNet.jpg'),
    RetailerNet: require('./RetailerNet.jpg'),
    ERP: require('./ERP.jpg'),
    ERP2: require('./ERP2.jpg'),
    Customized: require('./Customized.png'),
    BrainS: require('./BBBB.jpg'),
    //AboutUs
    Sir2: require('./Sir2.jpg'),
    Sir3: require('./Sir3.jpg'),
    Sir4: require('./Sir4.jpg'),
    Sir5: require('./Sir5.jpg'),

    //Product
    Logo2Health: require('./LogoHealth.jpg'),
    FoodNet3: require('./FoodNet3.png'),
    S1: require('./School1.jpg'),
    S2: require('./School2.jpg'),
    C3: require('./Custom2.jpg'),
    AR: require('./AR.jpg'),
    VR2: require('./VR2.jpg'),
    GD: require('./GameDevelopment.jpg'),

    Software: require('./Software.jpg'),

    //TopProduct
    LogoHealth: require('./LogoHealth.jpg'),
    LogoEdu: require('./LogoEdu.jpg'),
    LogoFood: require('./FoodNet3.png'),
    LogoAI: require('./LogoAI.jpg'),
    LogoERP: require('./LogoERP.jpg'),
    LogoCustom: require('./LogoCustom.jpg'),
    LogoRetailer: require('./LogoRetailer.jpg'),
    LogoVR: require('./LogoVR.jpg'),
    //Your Business
    Hosted: require('./Hosted.jpg'),
    Enterprise: require('./Enterprise.jpg'),

    //Our pride
    Judhho71: require('./Judhho71.jpg'),
    Judhho1: require('./Judhho1.jpg'),
    Judhho2: require('./Judhho2.jpg'),
    Judhho3: require('./Judhho3.jpg'),
    Judhho4: require('./Judhho4.jpg'),
    Judhho5: require('./Judhho5.jpg'),
    Judhho6: require('./Judhho6.jpg'),
    Judhho7: require('./Judhho7.jpg'),
    Judhho8: require('./Judhho8.jpg'),
    Judhho9: require('./Judhho9.jpg'),
    //Who we are
    MainLogo: require('./MainLogo.jpg'),

    consulting: require('./consulting.jpg'),
    business: require('./business.jpg'),
    Sir: require('./Sir.jpg'),
    //Training Gallery
    tg1: require('./S1.JPG'),
    tg2: require('./S2.JPG'),
    tg3: require('./S3.JPG'),
    tg4: require('./S4.PNG'),
    tg5: require('./S5.PNG'),
    tg6: require('./S6.PNG'),
    tg7: require('./S7.PNG'),
    tg8: require('./S8.PNG'),
    tg9: require('./S9.PNG'),
    tg10: require('./S10.PNG'),
    tg11: require('./S11.PNG'),
    tg12: require('./S12.PNG'),
    tg13: require('./S13.PNG'),
    tg14: require('./S14.PNG'),
    tg15: require('./S15.PNG'),
    tg16: require('./S16.PNG'),
    tg17: require('./S17.PNG'),
    tg18: require('./S18.PNG'),
    //Mobile game
    m1: require('./Mobile.jpg'),
    m2: require('./AW.jpg'),
    mm2: require('./AW2.jpg'),
    mm3: require('./AW3.jpg'),
    m3: require('./BD.jpg'),
    m33: require('./BD3.jpg'),
    m5: require('./BD2.jpg'),
    mm5: require('./BD4.jpg'),
    m7: require('./DG.jpg'),
    mm7: require('./DG2.jpg'),
    m8: require('./GCC.jpg'),
    mm8: require('./GCC2.jpg'),
    m9: require('./HA.jpg'),
    mm9: require('./HA2.jpg'),
    m10: require('./HTPT.jpg'),
    mm10: require('./HTPT2.jpg'),
    m11: require('./KD2.jpg'),
    mm11: require('./KD3.jpg'),
    mmm11: require('./KD4.jpg'),
    m12: require('./KYB.jpg'),
    mm12: require('./KYB2.jpg'),
    m13: require('./LL.jpg'),
    m14: require('./LMO.jpg'),
    mm14: require('./LMO2.jpg'),
    m15: require('./MC.jpg'),
    m6: require('./Ci.jpg'),
    m16: require('./ME.jpg'),
    mm16: require('./ME2.jpg'),
    m17: require('./PF.jpg'),
    mm17: require('./PF2.jpg'),
    m18: require('./PPM.jpg'),
    mm18: require('./PPM2.jpg'),
    m19: require('./SMI.jpg'),
    mm19: require('./SMI2.jpg'),
    m20: require('./SMO.jpg'),
    mm20: require('./SMO2.jpg'),
    m21: require('./SMTH.jpg'),
    mm21: require('./SMTH2.jpg'),
    m22: require('./TD.jpg'),
    mm22: require('./TD2.jpg'),
    m23: require('./WH.jpg'),
    mm23: require('./WH2.jpg'),




    //EGIP
    EGIP: require('./EGIP.jpg'),
    Camp: require('./Camp.jpg'),
    Dream: require('./Dream.jpg'),
    E1: require('./AIUB1.jpg'),
    E2: require('./IUBAT.jpg'),
    E3: require('./IUBAT2.jpg'),
    //BrainEngineering
    Brain: require('./Brain.jpg'),
    Brain1: require('./d.jpg'),
    Brain2: require('./Brain55.jpg'),
    Brain3: require('./Suraiya.jpg'),
    Brain4: require('./Syam.jpg'),
    Brain5: require('./Mahabub.jpg'),
    Brain6: require('./ATI.jpg'),
    Brain7: require('./IUB.jpg'),
    Brain8: require('./ICT Crest.jpg'),
    Brain9: require('./UAP.jpg'),
    Brain10: require('./BRAC.jpg'),
    Brain11: require('./AIUB2.jpg'),
    Brain111: require('./AIUB3.jpg'),
    Brain1111: require('./AIUB4.jpg'),
    Brain11111: require('./AIUB5.jpg'),
    AIUB1: require('./AIUB6.jpg'),
    AIUB2: require('./AIUB7.jpg'),
    AIUB3: require('./AIUB8.jpg'),
    AIUB4: require('./Backdrop.jpg'),
    AIUB5: require('./AIUB9.jpg'),
    AIUB6: require('./AIUB10.jpg'),
    AIUB7: require('./AIUB11.jpg'),
    AIUB8: require('./AIUB12.jpg'),
    AIUB9: require('./AIUB13.jpg'),
    AIUB10: require('./AIUB14.jpg'),
    AIUB11: require('./AIUB15.jpg'),
    AIUB12: require('./AIUB16.jpg'),
    AIUB13: require('./AIUB17.jpg'),

    Brain12: require('./NU.jpg'),
    Brain13: require('./NU2.jpg'),
    Brain14: require('./ULAB.jpg'),

    Neuro1: require('./Neuro1.jpg'),
    Neuro2: require('./ACI2.jpg'),
    Neuro3: require('./ACI3.jpg'),

    //BrainEngineering Part 2
    P2: require('./Part2.1.jpg'),
    P3: require('./Part2.2.jpg'),
    P4: require('./Part2.3.jpg'),
    P5: require('./Part2.4.jpg'),
    P6: require('./Part2.5.jpg'),
    P7: require('./banner.jpg'),
    //IUBAT
    IUBAT1: require('./IUBAT3.jpg'),
    IUBAT2: require('./IUBAT4.jpg'),
    IUBAT3: require('./IUBAT5.jpg'),
    IUBAT4: require('./IUBAT6.jpg'),
    IUBAT5: require('./IUBAT7.jpg'),
    IUBAT6: require('./IUBAT8.jpg'),
    IUBAT7: require('./IUBAT9.jpg'),

    //History
    H2: require('./History2.PNG'),
    H3: require('./History3.PNG'),
    H4: require('./History4.PNG'),
    H5: require('./History5.PNG'),
    H6: require('./History6.PNG'),
    H7: require('./History6.PNG'),
    //Consulting
    C1: require('./Consult.jpg'),
    C2: require('./Consult2.jpg'),
}

export default Images;