import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import ScrollToTop from "./Pages/ScrollToTop";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import History from "./Pages/About/History";

import HealthNet from "./Pages/Products/HealthNet";
import Game from "./Pages/Products/Game";
import FoodNet from "./Pages/Products/FoodNet";
import EduNet from "./Pages/Products/EduNet";
import RetailerNet from "./Pages/Products/RetailerNet";
import ERP from "./Pages/Products/ERP";
import Customized from "./Pages/Products/Customized";


import WhoWeAre from "./Pages/CorporateTraining/WhoWeAre/WhoWeAre";
import Consultancy from "./Pages/CorporateTraining/Consultancy/Consultancy";
import AcademicConsultancy from "./Pages/CorporateTraining/Consultancy/AcademicConsultancy";
import BusinessProcess from "./Pages/CorporateTraining/Consultancy/BusinessProcess";
import ItTransformation from "./Pages/CorporateTraining/Consultancy/ItTransformation";
import TrainingDetails from "./Pages/CorporateTraining/TrainingDetails/TrainingDetails";
import Profile from "./Pages/CorporateTraining/TrainingDetails/Profile";
import TrainingGallery from "./Pages/CorporateTraining/TrainingGallery/TrainingGallery";
import EGIP from "./Pages/EGIP/EGIP";
import DayLongCamp from "./Pages/EGIP/DayLongCamp";

import Games from "./Pages/Games/Games";
import Juddho71 from "./Pages/Games/Juddho71";
import MobileGame from "./Pages/Games/MobileGame";
import PcGame from "./Pages/Games/PcGame";
import HatirjheelDreamBegins from "./Pages/Games/HatirjheelDreamBegins";
import WeMakeGames from "./Pages/WeMakeGames/WeMakeGames";

import Media from "./Pages/Media/Media";
import BrainEngineering from "./Pages/BrainEngineering/BrainEngineering";
import BrainEngineering2 from "./Pages/BrainEngineering/BrainEngineering2";
import BrainEngineeringAIUB from "./Pages/BrainEngineering/BrainEngineeringAIUB";
import BrainEngineeringIUBAT from "./Pages/BrainEngineering/BrainEngineeringIUBAT";
import BrainEngineeringAll from "./Pages/BrainEngineering/BrainEngineeringAll";
import BrainEngineeringResearch from "./Pages/BrainEngineering/BrainEngineeringResearch";



function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/about/our-history">
            <History />
          </Route>
          <Route exact path="/corporate-training/who-we-are">
            <WhoWeAre />
          </Route>
          <Route exact path="/products/health-net">
            <HealthNet />
          </Route>
          <Route exact path="/products/game">
            <Game />
          </Route>
          <Route exact path="/products/food-net">
            <FoodNet />
          </Route>
          <Route exact path="/products/edu-net">
            <EduNet />
          </Route>
          <Route exact path="/products/retailer-net">
            <RetailerNet />
          </Route>
          <Route exact path="/products/ERP">
            <ERP />
          </Route>
          <Route exact path="/products/customized-software">
            <Customized />
          </Route>

          <Route exact path="/corporate-training/consultancy">
            <Consultancy />
          </Route>
          <Route exact path="/corporate-training/training-details">
            <TrainingDetails />
          </Route>
          <Route exact path="/corporate-training/training-details/profile">
            <Profile />
          </Route>
          <Route exact path="/corporate-training/training-gallery">
            <TrainingGallery />
          </Route>
          <Route exact path="/corporate-training/academic-consultancy">
            <AcademicConsultancy />
          </Route>
          <Route exact path="/corporate-training/business-process">
            <BusinessProcess />
          </Route>
          <Route exact path="/corporate-training/it-transformation">
            <ItTransformation />
          </Route>
          <Route exact path="/brain-engineering">
            <BrainEngineering />
          </Route>
          <Route exact path="/brain-engineering2">
            <BrainEngineering2 />
          </Route>
          <Route exact path="/brain-engineering-aiub">
            <BrainEngineeringAIUB />
          </Route>
          <Route exact path="/brain-engineering-iubat">
            <BrainEngineeringIUBAT />
          </Route>
          <Route exact path="/brain-engineering-all">
            <BrainEngineeringAll />
          </Route>
          <Route exact path="/brain-engineering-research">
            <BrainEngineeringResearch />
          </Route>
          <Route exact path="/EGIP/EGIP-details">
            <EGIP />
          </Route>
          <Route exact path="/EGIP/day-long-camp">
            <DayLongCamp />
          </Route>
          <Route exact path="/Games/games">
            <Games />
          </Route>
          <Route exact path="/Games/juddho71">
            <Juddho71 />
          </Route>
          <Route exact path="/Games/hatirjheel-dream-begins">
            <HatirjheelDreamBegins />
          </Route>
          <Route exact path="/Games/mobile-game">
            <MobileGame />
          </Route>
          <Route exact path="/Games/pc-game">
            <PcGame />
          </Route>
          <Route exact path="/Games/we-make-games">
            <WeMakeGames />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/media">
            <Media />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}
export default App;

// {
//   <header className="App-header">

//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <h1></h1>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header> .current{display:block}
// }
