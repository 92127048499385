const Images2 = {
    //Karnaphuli
    a: require('./IMG_6576.JPG'),
    b: require('./IMG_6587.JPG'),
    c: require('./IMG_6592.JPG'),
    d: require('./IMG_6595.JPG'),
    e: require('./IMG_6602.JPG'),
    f: require('./IMG_6607.JPG'),
    g: require('./IMG_6608.JPG'),
    h: require('./IMG_6635.JPG'),
    i: require('./IMG_6636.JPG'),
    j: require('./IMG_6680.JPG'),
    k: require('./IMG_6739.JPG'),
    //Apex
    l: require('./2.JPG'),
    m: require('./Audience-1.JPG'),
    n: require('./Audience-2.JPG'),
    o: require('./Audience-4.JPG'),
    p: require('./Gro Photo.JPG'),
    q: require('./IMG_4828.JPG'),
    r: require('./IMG_4847.JPG'),
    s: require('./IMG_4874.JPG'),
    t: require('./IMG_4951.JPG'),
    u: require('./IMG_4959.JPG'),
    //BAT(Business Automation)
    v: require('./7.JPG'),
    w: require('./BAT Group.JPG'),
    x: require('./IMG_7073.JPG'),
    y: require('./IMG_7086.JPG'),
    z: require('./IMG_7118.JPG'),
    //Mostafa Group
    A: require('./IMG_5290.JPG'),
    B: require('./IMG_5305.JPG'),
    C: require('./IMG_5303.JPG'),
    D: require('./IMG_5298.JPG'),
    //Hawar IT
    E: require('./IMG_6973.JPG'),
    F: require('./IMG_6812.JPG'),
    G: require('./IMG_6835.JPG'),
    H: require('./IMG_6841.JPG'),
    I: require('./IMG_6852.JPG'),
    J: require('./IMG_6872.JPG'),
    K: require('./IMG_6923.JPG'),
    L: require('./IMG_6926.JPG'),
    M: require('./IMG_6927.JPG'),
    N: require('./IMG_6933.JPG'),
    O: require('./IMG_6947.JPG'),
    //BioPharma Ldt.
    P: require('./01.JPG'),
    Q: require('./5.JPG'),
    R: require('./08.JPG'),
    S: require('./IMG_6117.JPG'),
    T: require('./IMG_6121.JPG'),
    U: require('./IMG_6136.JPG'),
    //General Pharmaceutical Ltd.
    V: require('./With General Pharma Executives.JPG'),
    W: require('./20140610_114208.jpg'),
    X: require('./20140610_114238.jpg'),
    Y: require('./20140610_173129.jpg'),
    Z: require('./20140610_173159.jpg'),
    ZZ: require('./20140610_174418.jpg'),
    //Renata Ltd.
    BB: require('./1.JPG'),
    AA: require('./22.JPG'),
    CC: require('./3.JPG'),
    DD: require('./4.JPG'),
    EE: require('./55.JPG'),
    FF: require('./6.JPG'),
    GG: require('./77.JPG'),
    HH: require('./FB1.JPG'),
    II: require('./IMG_5547.JPG'),
    JJ: require('./IMG_5675.JPG'),
}

export default Images2; 