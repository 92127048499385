import React from 'react'
import ReactPlayer from 'react-player';

function WeMakeGames() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br></br><br></br>
                        <p class="fs-5 text-justify">
                            <h1 class=" text-center mt-5 mb-0" style={{ color: "#FE7E00" }}>WE MAKE GAMES</h1> <br />
                            {/* <h3 class=" text-center  mt-0 mb-4" > Message from the CEO</h3> */}
                            “We Make Games” is an initiative to explore the possibilities of idea generations and development of computer games among young minds studying in different level at schools, colleges and universities. <br /><br />
                            MassiveStar Studio Ltd. is a game research and development organization working with games with a view to promote Bangladeshi culture, heritage, social life and tradition nationally and internationally. The objective of the program is to transform the computer games-player to games-developer and idea generator that make the young generation future focused, responsible leaders, IT savvy, international citizen and finally experienced professionals and entrepreneur. <br /><br />
                            With a dream of branding Bangladesh as a video games maker country in the world, we are organizing the event WMG in 400 selected schools and colleges throughout the country where “Channel i” is playing the role as media partner. The program is comprising with four phases, (a) Explore the games world and explore the possibilities (b) Organizing idea generation camp (c) Game making camp (d) Grooming session and nationwide competition. The first phase of the camp has been started from December 22, 2013 and will be continued till December 30, 2014. The eligibility criteria of the participant students are (a) Computer game player with age above 8 years (b) Sharp, intelligent, attentive and good at Math. <br /><br />
                            SM Mahabub Alam <br />
                            CEO <br />
                            MassiveStar Studio Ltd. <br /> <br />
                            <div class="col-lg-7 col-md-12 offset-lg-3 mb-4 ">
                                <ReactPlayer className="react-player" url="https://youtu.be/QHYg-rTru1I?" width='100%' />
                            </div>
                            We Make Games: A reality of the day is, a majority of young people play computer games; and many, to certain extent, are perhaps addicted to it hours are spent in games. A question that we at MassiveStar Studio poses is: how can we maximize such zeal and passion for games into greater good? An innovative idea was borne We Make Games. <br /> <br />
                            We Make Games (WMG) is an initiative to explore the possibilities of idea- generations and development of computer games among young minds currently studying in different levels of schools, colleges, and universities. <br /> <br />
                            The main objective of the program We Make Games is to transform games-centric children, teens, and university freshmen games players, from being called Games Poka into Games Developers. Through developing games these students (at any level) will eventually be ready to enter into the fascinating world of programming. <br /> <br />
                            It is worth mentioning that in the year 2020, around 20 millions programmers or coders will be required in USA alone. Great careers are awaiting them. We can jump start their careers now through “We Make Games”! <br /> <br />
                            <span class=" fw-bold">The program objectives:</span>
                            <ul>
                                <li>We want to transform games player to games maker.</li>
                                <li>Games playing are wastage of time but making games is building career.</li>
                                <li>Analytical skill will grow periodically.</li>
                                <li>Computer programming will be easier to learn at early age.</li>
                                <li>New generation will be Hi-tech oriented.</li>
                                <li>Will be more interactive with modern world.</li>
                                <li>To create ability for making world renowned software & games.</li>
                                <li>The developed games will have positive contribution to GDP in future.</li>
                                <li>Branding Bangladesh as “Games Developing Country”.</li>
                            </ul>
                            <span class=" fw-bold">The eligibility criteria of the participant students are</span>
                            <ul>
                                <li>Computer game player with age above 8 years</li>
                                <li>Sharp, intelligent, attentive and good at Math</li>
                                <li>Good analytical skill with full of dedication.</li>
                            </ul>
                            <span class=" fw-bold">Requirements for students:</span>
                            <ul>
                                <li>Game player</li>
                                <li>Laptop</li>
                                <li>Game toolkit</li>
                                <li>Registration (students will get game toolkit during the workshop)</li>
                            </ul>
                            <span class=" fw-bold">Your deliverables:</span>
                            <ul>
                                <li>Class notice about “We Make Games”</li>
                                <li>Auditorium</li>
                                <li>Sound system</li>
                                <li>Multimedia Projector</li>
                            </ul>
                            <span class=" fw-bold">Our deliverables:</span>
                            <ul>
                                <li>Banner</li>
                                <li>Game Engine</li>
                                <li>Game model</li>
                                <li>Other necessary tools</li>
                            </ul>
                            <span class=" fw-bold">Customizable area of workshop:</span>
                            <ul>
                                <li>The Workshop will be conducted after class program twice/once in a month.</li>
                                <li>The session duration will be around 2/4 hours.</li>
                                <li>The Game making team can be formed from every individual campus of the school (if any).</li>
                            </ul>
                            <h3 class=" text-center  mt-0 mb-4" >Mentor’s and Consultant’s Panel</h3>
                            <span class=" fw-bold text-break">SM Mahabub Alam</span> <br />
                            Chief Executive Officer, <br /> MassiveStar Studio, Massive Business Academy <br /> <br />
                            Mr. SM Mahabub Alam is a graduate in Electrical and Electronics Engineering (EEE) from BUET and completed MBA with Marketing Major from East West University. He was a CEO of Daffodil Group. In his 20 years of professional career, he played different leadership roles in companies such as Thakral, Beximco, Wincom, ISL and ReliSource. His broad management expertise encompasses International & National Marketing, Strategic Business, Management, HR Development, and Operation. Mr. Alam possess vertical industry skill sets in IT, Education, RMG, Petroleum Distribution, Healthcare, Bank, Insurance and Manufacturing. As a top level management, he travels widely, and has been trained from USA, UK, Malaysia, Netherlands, Denmark, and India on Strategy, International Marketing, HR, Motivation, Salesmanship, Management technique, IT etc. <br /> <br />
                            <span class=" fw-bold text-break">Dr. Md. Mostofa Akbar</span> <br />
                            Professor, <br /> Dept of CSE, <br /> Bangladesh University of Engineering & Technology(BUET) <br /> Chief Consultant <br /> <br />
                            Dr. Md. Mostofa Akbar holds a BSc. and MSc. in Computer Science & Engineering from BUET. He received his PhD in Computer Science from the University of Victoria, Australia. As an academician, Dr. Akbar has carried out extensive research and his articles has been published in many refereed international journals. Dr. Akbar has also published many books such as Theoretical Statistics, Higher Secondary Statistics Part I and Higher Secondary Statistics Part II. He has also conducted many consultancy projects during his illustrious professional life. <br /> <br />
                            <span class=" fw-bold text-break">Dr. WONG KH</span> <br />
                            Principal at Perdana College of Malaysia; UCSI University, Malaysia. <br /> CEO, Perdana Professional Institute <br /> Project Director <br /> <br />
                            Dr. Wong KH has been involved in Cross Border Higher Education, university collaboration, and research over the past 25 years. His research, in association with University of Malaya and Multimedia University, has been published in international referee journals. His interests encompass higher education management, curriculum development, teaching & learning, quality assurance, photonics and information management. Dr Wong graduated with a MPhil in Computational Physics and PhD in Laser Physics from University of Malaya, Malaysia. <br /> <br />


                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WeMakeGames