import React from 'react'
import Images from "../../Assets/Images/index";
function RetailerNet() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br />  <br /> <br />
                        <h2 class="fw-bold text-center fs-3 mt-2 " style={{ color: "#FE7E00" }}>Retail Management Software</h2>
                        <img src={Images.RMS3} alt=' image' class=" img-fluid mb-3  w-100" />
                        <p class="fs-5 text-justify mt-2">

                            MassiveStar provide for you with the best Retail management software in Bangladesh. Today, POS management software systems have become one kind of blessing software system. It helps you access sales data and drives your strategy. POS Software can help the store owners by providing multiple services in one place and streamlining running a store. Retail Point of Sales (POS) Software is an online-based software that can be used anywhere in the world. Check your business reports from any time updates from your back office. <br /> <br />
                            POS or retail software’s abilities have been extended to process monetary transactions, keep customer service records, book and allocate facilities, Inventory tracking, invoicing, and monitoring of debts and outstanding payments. <br /> <br />
                            {/* <img src={Images.Camp} alt=' image' class="mt-4 img-fluid float-left me-5 mb-3  w-50" /> */}
                            <h2 class=" text-center fs-3 mt-2 mb-3" style={{ color: "#FE7E00" }}>Features Of Our Best Retail Management Software</h2>
                            <ul class="  float-left w-50">
                                <li>Sales Management</li>
                                <li> Purchase Management</li>
                                <li> General Accounts Management</li>
                                <li> Products Management</li> <br />
                                {/* <li> Reports Management</li> */}

                            </ul>
                            <ul class="  float-right w-50">
                                <li> Customer Management</li>
                                <li>Suppliers Management</li>
                                <li>SMS Management</li>
                                <li> Taxes Management</li> <br />

                            </ul>
                            <h2 class=" text-center fs-3 mt-4" style={{ color: "#FE7E00" }}>Retail Management Software Details</h2> <br />
                            <span class="fw-bold  fs-4">Sales Management:</span> <br />
                            Sales report of a particular day, item, shop, shift and create new sales, sales list, product return, customer due to collection and create courier. You can mark the products quickly and take proper initiatives for those products which are not selling well. <br /> <br />
                            <span class="fw-bold  fs-4">Purchase Management:</span> <br />
                            It is about your purchase. Every individual product’s purchase data is saved in your database with all details. Easily manage all your purchase summary, purchase details, add purchase, purchase list, payment, purchase return, and purchase return receive. <br /> <br />
                            <span class="fw-bold  fs-4">General Accounts:</span> <br />
                            By this feature on our POS software in Bangladesh, you are able to manage your all accounts information like Accounts, GI Accounts, Chart of Accounts, payments methods, parties. you can also check debit voucher, credit voucher, anytime you transfer your funds. <br /> <br />
                            <span class="fw-bold  fs-4">Products Management:</span> <br />
                            Shop management software in Bangladesh allows you to keep unlimited products or things here with stock information, manage brands, manage categories, manage units, add product, inventory information, barcode number etc. <br /> <br />
                            <span class="fw-bold  fs-4">Customer Management:</span> <br />
                            You can enormous customer’s names and details like address, mobile number, email address, add customers by csv etc. By observing your customer’s list and their purchase, you can easily find out your best customers. <br /> <br />
                            <span class="fw-bold  fs-4">Suppliers Management:</span> <br />
                            This POS management software also enables you to save information about your suppliers with their contact details and manage suppliers. <br /> <br />
                            <span class="fw-bold  fs-4">SMS Management:</span> <br />
                            Extra SMS modules that are Best retail management software in Bangladesh. Custom SMS sending option we give you for your business to communicate the customers. <br /> <br />
                            <span class="fw-bold  fs-4">Taxes Management:</span> <br />
                            Many POS systems support taxes. After selling a product, you can manage the tax from it with this feature. <br /> <br />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RetailerNet