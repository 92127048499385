import React from 'react'

function FoodNet() {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br />  <br /> <br /><br />
                        <div className='d-lg-flex d-md-block bg-color'>
                            <div className='text-center mt-5 pl-1'>
                                <p>
                                    <span className='foodnet'>FoodNet</span>
                                    <br></br>
                                    <span className='part-2'>All-In-One Restaurant Management <br></br>Software</span>
                                </p>
                            </div>
                            <br></br>
                            <div className='d-flex mt-4'>
                                <img className='img-style' src={require('../../Assets/foodnet/allVersion.png')} alt='image' />
                            </div>
                            <div className='play-div'>
                                <a target="_blank" href="https://youtu.be/b8rEfHXiut0">
                                    <i class="fa-sharp fa-solid fa-circle-play play-icon"></i>
                                </a>
                            </div>
                        </div>
                        <div class="d-grid gap-2 col-6 mx-auto">
                            <a target="_blank" href='https://restaurant.food.bein-mcl.com/request-demo' class="btn btn-info" type="button">Request for Demo</a>
                        </div>
                        <div>
                            <h2 className='text-center mt-5 mb-4'><b>Key Features For Restaurant Management System</b></h2>
                            <div className='d-lg-flex d-md-block mt-5 mb-5'>
                                <img className='img-border' src={require('../../Assets/foodnet/pos.PNG')} alt='image' />
                                <div className='ml-4'>
                                    <h3>Restaurant POS Software</h3>
                                    <p>
                                        Restaurant point-of-sale or POS software is a vital component for managing the restaurant order.
                                        Restaurant POS window has all you need to manage the order at your fingertips.
                                        Features of Restaurant POS Software are below:
                                    </p>
                                    <ul>
                                        <li>Take Order from customer</li>
                                        <li>Manage online order</li>
                                        <li>Assign a customer to a waiter</li>
                                        <li>Print invoice for complete orders</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='d-lg-flex d-md-block justify-content-end mt-5 mb-5'>
                                <div className='col-md-6 mt-2'>
                                    <h3>Dashboard</h3>
                                    <p>
                                        The dashboard is a very important part of any software.
                                        To bring all the necessary information about your business to your fingertip,
                                        we make an outstanding dashboard. You will get the list of.
                                    </p>
                                    <ul>
                                        <li>Latest Order</li>
                                        <li>Trending Items</li>
                                        <li>Sales Reports</li>
                                        <li>Table Details</li>
                                    </ul>
                                </div>
                                <img className='img-border' src={require('../../Assets/foodnet/dashboard.PNG')} alt='image' />
                            </div>
                            <div className='d-lg-flex d-md-block mt-5 mb-5'>
                                <img className='img-border' src={require('../../Assets/foodnet/order.PNG')} alt='image' />
                                <div className='col-md-6 ml-4 mt-2'>
                                    <h3>Order Management System</h3>
                                    <p>
                                        Order management refers to the process of receiving, processing,
                                        and fulfilling customer orders for goods or services.
                                        It involves coordinating and managing the flow of orders from the point
                                        of sale to the point of delivery or service, ensuring that the orders
                                        are fulfilled accurately, on time, and in compliance with customer requirements.
                                    </p>
                                    <ul>
                                        <li>Order List</li>
                                        <li>Online order</li>
                                        <li>QR Code order</li>
                                        <li>Pending Order</li>
                                        <li>Complete Order</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='d-lg-flex d-md-block mt-5 mb-5'>
                                <div className='col-md-6 mr-4 mt-2'>
                                    <h3>Real-time Reports</h3>
                                    <p>
                                        Report creation is very important for a company. But it burns too much time.
                                        On the other hand, this software has an excellent feature that helps to create real-time reports.
                                        Also, it can generate accurate and error-free reports. You will find it.
                                    </p>
                                    <ul>
                                        <li>Daily Sales Report</li>
                                        <li>Purchase Report</li>
                                        <li>Monthly Sales Report</li>
                                        <li>Yearly Sales Report</li>
                                        <li>Employee Report</li>
                                    </ul>
                                </div>
                                <img className='img-border' src={require('../../Assets/foodnet/report1.PNG')} alt='image' />
                            </div>
                            <div className='d-lg-flex d-md-block mt-5 mb-5'>
                                <img className='img-border' src={require('../../Assets/foodnet/reservation.PNG')} alt='image' />
                                <div className='col-md-6 ml-4 mt-2'>
                                    <h3>Table Reservation</h3>
                                    <p>
                                        Table reservation is a very important factor for any restaurant management system.
                                        Traditionally, if a customer wants to book a table, he or she may need to call the helpline and book the table.
                                        On the other hand, using this software, you can receive a customer's online order as well as customers can
                                        reserve a table through using a customer app.
                                    </p>
                                    <ul>
                                        <li>Customer can reserve table by himself</li>
                                        <li>Reservation from Admin panel</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='d-lg-flex d-md-block mt-5 mb-5'>
                                <div className='col-md-6 mr-4 mt-2'>
                                    <h3>Human Resources Management</h3>
                                    <p>
                                        Human Resource Management (HRM) refers to the function within an
                                        organization that is responsible for managing and developing its
                                        human resources. HRM encompasses a wide range of activities related
                                        to managing employees, including recruitment and selection, training
                                        and development, performance management, compensation and benefits,
                                        employee relations, and regulatory compliance.
                                    </p>
                                    <ul>
                                        <li>Attendence</li>
                                        <li>Leave</li>
                                        <li>Holidays</li>
                                        <li>Departments</li>
                                        <li>Employee</li>
                                    </ul>
                                </div>
                                <img className='img-border' src={require('../../Assets/foodnet/hr.PNG')} alt='image' />
                            </div>
                            <div className='d-lg-flex d-md-block mt-5 mb-5'>
                                <img className='img-border' src={require('../../Assets/foodnet/inventory.PNG')} alt='image' />
                                <div className='col-md-6 ml-4 mt-2'>
                                    <h3>Inventory management</h3>
                                    <p>
                                        Inventory management is a critical aspect of any business that deals with products, goods or materials.
                                        It involves the process of controlling and optimizing the stock levels of a company's inventory in order to
                                        minimize costs, increase efficiency and maximize profits.
                                    </p>
                                    <ul>
                                        <li>Supplier</li>
                                        <li>Inventory Invoices</li>
                                        <li>New Inventory</li>
                                        <li>Inventory Transfer within different outlets</li>
                                        <li>Inventory Distribution among Chefs</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='d-lg-flex d-md-block mt-5 mb-5'>
                                <div className='col-md-6 mt-2 mr-4'>
                                    <h3>Recipe, Ingredient and Food Management</h3>
                                    <p>
                                        Recipe, ingredient and food management refer to the processes involved in organizing and optimizing
                                        the food-related aspects of a business, whether it's a restaurant, bakery, catering service or any
                                        other food-based establishment. These processes are crucial to ensuring that the business runs smoothly,
                                        efficiently and profitably.
                                    </p>
                                    <ul>
                                        <li>Ingredient</li>
                                        <li>Recipe</li>
                                        <li>Food price from recipe</li>
                                    </ul>
                                </div>
                                <img className='img-border' src={require('../../Assets/foodnet/recipe.PNG')} alt='image' />
                            </div>
                            <div className='d-lg-flex d-md-block mt-5 mb-5'>
                                <img className='img-border' src={require('../../Assets/foodnet/waste.PNG')} alt='image' />
                                <div className='col-md-6 ml-4 mt-2'>
                                    <h3>Waste management</h3>
                                    <p>
                                        Waste management is the process of collecting, transporting, treating, and disposing of waste
                                        for an item. It includes a range of activities,
                                        such as waste reduction, keep record of waste, as well as generate ideas for waste reduction.
                                    </p>
                                    <ul>
                                        <li>Waste for per Employee</li>
                                        <li>Daily Waste</li>
                                        <li>Weekly Waste</li>
                                        <li>Monthly Waste</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='d-lg-flex d-md-block mt-5 mb-5'>
                                <div className='col-md-6 ml-4 mt-2'>
                                    <h3>Branch Management</h3>
                                    <p>
                                        Effective branch management is critical to ensuring that each branch is performing optimally and
                                        meeting the overall goals of the company. It involves managing employees, finances, sales,
                                        customer service, and operations at each branch, while maintaining consistency and
                                        adherence to company policies and procedures.
                                    </p>
                                    <ul>
                                        <li>Multiple branches</li>
                                        <li>User will view branch his Location based</li>
                                        <li>Individual Food Management for branches</li>
                                        <li>Individual Employee Management for branches</li>
                                        <li>Individual Inventory Management for branches</li>
                                        <li>Transfer ingredients different branches</li>
                                    </ul>
                                </div>
                                <img className='img-border' src={require('../../Assets/foodnet/branch.PNG')} alt='image' />
                            </div>
                            <div className='d-lg-flex d-md-block mt-5 mb-5'>
                                <img className='img-border' src={require('../../Assets/foodnet/discount.PNG')} alt='image' />
                                <div className='col-md-6 ml-4 mt-2'>
                                    <h3>Discount management</h3>
                                    <p>
                                        Effective discount management requires a thorough understanding of the target market,
                                        competition, and the restaurants's financial goals. It involves determining the types
                                        and levels of discounts to offer, the timing and duration of the discount programs,
                                        and the communication channels to promote the discounts to customers.
                                    </p>
                                    <ul>
                                        <li>Discount for individual foods</li>
                                        <li>Coupon</li>
                                        <li>Personal Coupon/Discount</li>
                                        <li>Discount with a certain amount</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='pricing'>
                            <div className='single-card color1'>
                                <h3><b>Regular Price for Single Branch</b></h3>
                                <h4 className='price'>10,000 BDT</h4>
                                <div>
                                    <input className='form-control' type="checkbox" checked></input>POS System
                                    <input className='form-control' type="checkbox" checked></input>Inventory Management
                                    <input className='form-control' type="checkbox" checked></input>Report Genaration
                                    <input className='form-control' type="checkbox" checked></input>Waste Management
                                    <input className='form-control' type="checkbox" checked></input>Supplier Management
                                    <input className='form-control' type="checkbox" checked></input>Recipe Management
                                    <input className='form-control' type="checkbox" checked></input>Human Resource Management
                                    <input className='form-control' type="checkbox" checked></input><s>Branch Management</s>
                                    <input className='form-control' type="checkbox" checked></input>Install Support
                                    <input className='form-control' type="checkbox" checked></input><s>24/7 Support</s>
                                    <input className='form-control' type="checkbox" checked></input><s>Customize any feature</s>
                                </div>
                                <div class="d-grid gap-2 mx-auto mt-4">
                                    <a target="_blank" href='https://restaurant.food.bein-mcl.com/request-demo' class="btn btn-warning" type="button">Purchase </a>
                                </div>
                            </div>
                            <div className='single-card color2'>
                                <h3><b>Regular Price for Multiple Branch</b></h3>
                                <h4 className='price2'>15,000 BDT</h4>
                                <div>
                                    <input className='form-control' type="checkbox" checked></input>POS System
                                    <input className='form-control' type="checkbox" checked></input>Inventory Management
                                    <input className='form-control' type="checkbox" checked></input>Report Genaration
                                    <input className='form-control' type="checkbox" checked></input>Waste Management
                                    <input className='form-control' type="checkbox" checked></input>Supplier Management
                                    <input className='form-control' type="checkbox" checked></input>Recipe Management
                                    <input className='form-control' type="checkbox" checked></input>Human Resource Management
                                    <input className='form-control' type="checkbox" checked></input>Branch Management
                                    <input className='form-control' type="checkbox" checked></input>Install Support
                                    <input className='form-control' type="checkbox" checked></input>24/7 Support
                                    <input className='form-control' type="checkbox" checked></input><s>Customize any feature</s>
                                </div>
                                <div class="d-grid gap-2 mx-auto mt-4">
                                    <a target="_blank" href='https://restaurant.food.bein-mcl.com/request-demo' class="btn btn-success" type="button">Purchase </a>
                                </div>
                            </div>
                            <div className='single-card color3'>
                                <h3><b>Customizable Software</b></h3>
                                <p className='price3 py-1'>Vary according to the features</p>
                                <div>
                                    <input className='form-control' type="checkbox" checked></input>POS System
                                    <input className='form-control' type="checkbox" checked></input>Inventory Management
                                    <input className='form-control' type="checkbox" checked></input>Report Genaration
                                    <input className='form-control' type="checkbox" checked></input>Waste Management
                                    <input className='form-control' type="checkbox" checked></input>Supplier Management
                                    <input className='form-control' type="checkbox" checked></input>Recipe Management
                                    <input className='form-control' type="checkbox" checked></input>Human Resource Management
                                    <input className='form-control' type="checkbox" checked></input>Branch Management
                                    <input className='form-control' type="checkbox" checked></input>Install Support
                                    <input className='form-control' type="checkbox" checked></input>24/7 Support
                                    <input className='form-control' type="checkbox" checked></input>Customize any feature
                                </div>
                                <div class="d-grid gap-2 mx-auto mt-4">
                                    <a target="_blank" href='https://restaurant.food.bein-mcl.com/request-demo' class="btn btn-danger" type="button">Purchase </a>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div class="d-grid gap-2 col-6 mx-auto my-3">
                            <a target="_blank" href='https://restaurant.food.bein-mcl.com/request-demo' class="btn btn-info" type="button">Request for Demo</a>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FoodNet;