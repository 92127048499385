import React from 'react'
import Images from "../../Assets/Images/index";

function Game() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br /> <br /><br />
                        <h2 class="fw-bold text-center mt-2 " style={{ color: "#FE7E00" }}>Game Development</h2>
                        <img src={Images.GD} alt=' image' class="rounded mt-2 img-fluid float-left me-5 mb-3 w-100" />
                        <p class="fs-5 text-justify">
                            Our game development team uses the world's best real-time development platform to build winning and interactive 2D and 3D games with a faultless performance on Mobile, Web, PC, Console, etc. <br /> <br />
                            Our company is one of the best and most popular video game development companies in the world. Here you can turn your creative ideas into games by bringing the best quality and cost-effective solution. We run independent projects, jointly build games with the client's team, and provide further operational services. Our developer's expertise allows us to cover all gaming platforms and develop a fantastic product that matches the customer's vision and player's preferences. <br />   <br />
                            <h3 class="fw-bold text-center mt-2 fs-3" style={{ color: "#FE7E00" }}>Augmented Reality Development</h3>
                            <img src={Images.AR} alt=' image' class="rounded mt-2 img-fluid float-left me-5 mb-3 w-100" />
                            MassiveStar Augmented Reality Development Services help bring the virtual world closer to Reality. We develop AR apps with high-definition functionalities. As an Augmented Reality Development Company, we provide compelling Augmented Reality (AR) apps for Android, iOS, and Windows that take the user experience to a whole new level while improving brand value. <br /> <br />
                            <h3 class="fw-bold text-center mt-2 fs-3" style={{ color: "#FE7E00" }}>Virtual Reality Development</h3>
                            <img src={Images.VR2} alt=' image' class="mt-2 img-fluid float-left me-5 mb-3  w-100" />
                            Virtual Reality is an immersive, three-dimensional computer-generated experience where users interact with a digital world. MassiveStar is an innovative Virtual Reality Development company that offers custom VR experiences to empower your business. Our custom VR services provide the most ambitious scope and bring incredible ideas to life. Develop VR with us. <br /> <br />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Game