import React from 'react'
import Images from "../../Assets/Images/index";

function DayLongCamp() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br />  <br /> <br /> <br />
                        <span class="fw-bold text-break fs-3">DAY LONG CAMP</span>
                        <p class="fs-5 text-justify">
                            Entrepreneur Guidance and Implementation Program (EGIP) is a project presented by  Massive Academy of Business and Technology (MABT) with the objective to create enormous entrepreneur and top class professionals in the country. EGIP is one of the prime products of  MABT under the brand “Massive” Mentor products group. EGIP focuses on the potential youths and professionals who are interested to be entrepreneur in future or to become a self starter or to be top class executive. Under this program we provide Guidance, Motivation and Required Knowledge to be a successful entrepreneur. At the same time EGIP will also provide a networking business platform that makes the entrepreneurs dream easier. Finally that helps to start own business or other’s business as top executive and leader. <br />
                            <img src={Images.Camp} alt=' image' class="mt-4 img-fluid float-left me-5 mb-3  w-75" />
                            The following three (3) things will comprises with EGIP: <br />
                            <ul>
                                <li>Guidance</li>
                                <li>Motivation</li>
                                <li>Knowledge</li>
                            </ul>
                            The key component of the Guidance Program: <br />
                            Provide career counseling, guide the personnel to choose the right career as per individual’s deservedness and providing all required knowledge to start & manage a business: necessary guidance motivation knowledge, platform and support to develop as an entrepreneur or right professionals to start & manage a business.
                            Key Feature of Guidance Camp (GC): <br />
                            <ol>
                                <li>Build a businessman mentality.</li>
                                <li>Mind Mapping, visualization of future by doing mind exercise.</li>
                                <li>Renowned businessman and successful entrepreneur will come to share their experience.</li>
                                <li>Guidance and knowledge will be provided to grow decision making ability.</li>
                                <li>Task or guidance will depends on group & individual situation.</li>
                            </ol>
                            At the end of the Guidance Camp (GC) the participants can able to: <br />
                            <ol>
                                <li>Visualize their future by doing mind exercise.</li>
                                <li>Set his/her own vision & mission.</li>
                                <li>Grow their decision making ability.</li>
                                <li>Behave as a business personnel (development of the right attitude)</li>
                            </ol>
                            Our Offers: <br />
                            YOU can asses and evaluate YOURSELF by Day Long Guidance Camp (GC) on 1st Friday of every month (9:00 am-6:00pm) <br />
                            Outcome: <br />
                            The ultimate outcome of the program is, the juniors will be transformed as Top executive in own company or in others business. After completing the program, the participant will be able to: <br />
                            <ol>
                                <li>Evaluate and think him/her as a self starter.</li>
                                <li>Choose the right career Platform.</li>
                                <li>Be a Right Professional.</li>
                                <li>Gather Courage/Experience.</li>
                                <li>Be an Entrepreneur.</li>
                                <li>Get a clear Career Map.</li>
                            </ol>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DayLongCamp