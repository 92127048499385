import React from 'react'
import Images from "../../../Assets/Images/index";
import Images2 from "../../../Assets/Images2/Index2";
import Carousel from 'react-bootstrap/Carousel'
export default function
    () {
    return (
        <>
            <div className="container ">
                <div className="row pt-3">
                    <div className="col">
                        <br></br>
                        <h1 class=" text-center mt-5 mb-3" style={{ color: "#FE7E00" }}>CORPORATE TRAINING GALLERY</h1>
                        <span class="fw-bold ">Gallery of “Enhance Performance Through Attitude and NLP” at Brac-CDM, Savar.</span><br></br>
                        <Carousel>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg1} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg2} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg3} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg4} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg5} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg6} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg7} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg8} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg9} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg10} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg11} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg12} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg13} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg14} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg15} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg16} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg17} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images.tg18} /> </Carousel.Item>

                        </Carousel>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col">
                        <h1 class=" text-center">Karnaphuli Group</h1>
                        <Carousel>
                            <Carousel.Item><img className="d-block w-100" src={Images2.a} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.b} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.c} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.d} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.e} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.f} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.g} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.h} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.i} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.j} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.k} /> </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col">
                        <h1 class=" text-center">Apex Group</h1>
                        <Carousel>
                            <Carousel.Item><img className="d-block w-100" src={Images2.l} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.m} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.n} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.o} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.p} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.q} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.r} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.s} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.t} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.u} /> </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col">
                        <h1 class=" text-center">BAT(Business Automation)</h1>
                        <Carousel>
                            <Carousel.Item><img className="d-block w-100" src={Images2.v} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.w} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.x} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.y} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.z} /> </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col">
                        <h1 class=" text-center">Mostafa Group</h1>
                        <Carousel>
                            <Carousel.Item><img className="d-block w-100" src={Images2.A} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.B} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.C} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.D} /> </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col">
                        <h1 class=" text-center">Hawar IT</h1>
                        <Carousel>
                            <Carousel.Item><img className="d-block w-100" src={Images2.E} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.F} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.G} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.H} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.I} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.J} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.K} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.L} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.M} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.N} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.O} /> </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col">
                        <h1 class=" text-center">BioPharma Ldt.</h1>
                        <Carousel>
                            <Carousel.Item><img className="d-block w-100" src={Images2.P} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.Q} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.R} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.S} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.T} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.U} /> </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col">
                        <h1 class=" text-center">General Pharmaceutical Ltd..</h1>
                        <Carousel>
                            <Carousel.Item><img className="d-block w-100" src={Images2.V} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.W} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.X} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.Y} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.Z} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.ZZ} /> </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col">
                        <h1 class=" text-center">Renata Ltd.</h1>
                        <Carousel>
                            <Carousel.Item><img className="d-block w-100" src={Images2.BB} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.AA} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.CC} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.DD} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.EE} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.FF} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.GG} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.HH} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.II} /> </Carousel.Item>
                            <Carousel.Item><img className="d-block w-100" src={Images2.JJ} /> </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </>
    )
}
