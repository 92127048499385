import React, { useState } from 'react'
import { Viewer } from '@react-pdf-viewer/core'; // install this library
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import WinCom1 from './WinCom.pdf';

function History() {
    const [WinCom] = useState(WinCom1);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <>
            <div className="container">
                <br /> <br /> <br />
                <div className="row ">
                    <div className="col">
                        <h2 class="fw-bold text-center mt-2 mb-2"> Our History </h2>
                        <p class="fs-5 text-justify">
                            We started our journey in 1999 by making "HealthNet Clinical Management". After that we transforn into MassiveStarStudio Ltd.
                            MassiveStar started the journey in the year 2013 with a group of young talents under a very strong and experienced leadership. MSS is a true combination of experienced leadership and a group of talented young those who are dedicated and fully motivated with a massive dream. MassiveStar is a sister concern of ElevenPlus and MassivePlus.
                            The present government has started journey with the slogan, “Digital Bangladesh”. A huge development has been done already. Time has come to project the same. With the objective we have developed the game on Hatirjheel, where our local culture, heritage and dream have been reflected. By playing the game our new generation will look at Hatirjheel as the symbol of present and future development of Bangladesh.
                            “Hatirjheel, Dream Begins” will be the first computer series game from an organized group in the country and developed by our local engineers and young talents for the people of Bangladesh.
                        </p>
                        <div className="row">
                            <div className="col">
                                {WinCom && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                                    <Viewer fileUrl={WinCom}
                                        plugins={[defaultLayoutPluginInstance]} />
                                </Worker></>}
                            </div>
                        </div>

                    </div>
                </div> <br />
                <a class="btn btn-danger" role="button" href={WinCom}
                    download=" WinCom">
                    Download
                </a> <br /><br />
            </div>
        </>
    )
}

export default History