import React from 'react'
import Images from "../../../Assets/Images/index";

export default function AcademicConsultancy() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div class="col-12 ">
                        <br></br><br></br>
                        <h1 class=" text-center mt-4 mb-2" style={{ color: "#FE7E00" }}>CONSULTANCY</h1>
                        <img src={Images.consulting} alt=' image' class="img-fluid w-100 mb-4" />
                        <p class="fs-5 text-justify">
                            <span class="fw-bold">Academic Consultancy</span><br></br>
                            We now provide career and education support to all aspects of services to children and young people nationally and internationally.<br></br>
                            Besides providing quality education through our institute MABT (Massive Academy of Business and Technology) we are committed to support the development of education worldwide. In order to achieve this, we offer the highest quality education consultancy services to governments, international development agencies and other development partners. We work in partnership with many organizations to help raise the standards of children’s services across the country.<br></br><br></br>
                            Our services include:<br></br>
                            <ul> <li>Assisting with education policy development;</li>
                                <li>Advising on education financing;</li>
                                <li>Advising on education financing; </li>
                                <li>Teaching and learning resources to improve services & staff development;</li>
                                <li>Program and project design and management to social and institutional development;</li>
                                <li>Teachers Training;</li>
                                <li>Learning Feedback Systems Development;</li>
                                <li>Developing smart class with Latest technology tools;</li>
                                <li>School Management Systems;</li>
                                <li>School improvement program;</li>
                                <li>Project and program evaluation.</li>
                            </ul>

                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

