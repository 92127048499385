import React from 'react'
import ReactPlayer from 'react-player';
import Images from "../../Assets/Images/index";
function Games() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br /><br /> <br />
                        {/* <img src={Images.EGIP} alt=' image' class="mt-4 img-fluid float-left me-5 mb-3  w-50" /> */}
                        <h1 class=" text-center mt-3 " style={{ color: "#000" }}>Massive Juddho71</h1>
                        <div class="container text-justify">
                            <div class="row player-wrapper">
                                <div class="col-lg-4 col-md-12   ">
                                    <p>কাগজে গল্প লেখা যায়। এতদিন তাই হয়ে আসছে। চিত্রকরেরা ছবিতে গল্প লেখেন। কেউ কেউ মাটির মূর্তিতে গল্প লিখতে পারেন। কেউবা লোহায়, তামায় বা পিতলে গল্প লিখতে পারেন। সেলুলয়েডের ফিতে মানে সিনেমাতে গল্প লেখার পদ্ধতিও চলছে বহু যুগ ধরে। এসব পদ্ধতির বড় সমস্যা গল্পকার যেমন ইচ্ছা পাঠক বা দর্শককে নিয়ন্ত্রন করেন। এগুলো বড়জোর দ্বিমাত্রিক। আসুন আমরা ত্রিমাত্রিক গল্প দেখি যা দর্শক চাইলে পরিবর্তন করতে পারেন। আর হ্যাঁ এই পদ্ধতির আরও কিছু শুবিধা আছে, এই পদ্ধতির গল্পগুলো একটু মজারও হয়। দর্শকেরা নিছক দর্শক নয় তাই তারা গল্পের মধ্যে ঢুকে যেতে পারে। তাই গল্পের মজাও তারা অনেকদিন ধরে তারিয়ে তারিয়ে নিতে পারে। আগামী দুনিয়া হবে ত্রিমাত্রিক। আসুন আমরা তাকে স্বাগত জানাই।</p>
                                </div>
                                <div class="col-lg-8 col-md-12  mb-2 ">
                                    <ReactPlayer className="react-player" url="https://youtu.be/sPre_Cmhun0?" width='100%' />
                                </div>
                                <div class="col-lg-6 col-md-12  mb-2 ">
                                    <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=okWpZPsSmR8?" width='100%' />
                                </div>
                                <div class="col-lg-6 col-md-12  mb-2 ">
                                    <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=g3u4iaEVH0k?" width='100%' />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h1 class=" text-center   my-4" style={{ color: "#000" }}>Hatirjheel Dream Begins</h1>
                        <div class="container text-justify">
                            <div class="row player-wrapper">
                                <div class="col-lg-7 col-md-12  mb-2 ">
                                    <ReactPlayer className="react-player" url="https://youtu.be/vrLO0ao1Yks?" width='100%' />
                                </div>
                                <div class="col-lg-5 col-md-12 fs-5  ">
                                    <p> Hatirjheel is a series of games. The first games of this series is Dream Begins, a combination of reality as well as dream. We think Hatirjheel is the prime gift to the new generation and gift to the people of Bangladesh. We feel that the massive development of Bangladesh what so far have done must be projected to the new generation who have deep interest in information technology and dream for future Bangladesh.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h1 class=" text-center   my-4" style={{ color: "#000" }}>Mobile Games</h1>
                        <div class="container text-justify">
                            <div class="row player-wrapper">
                                <div class="col-lg-5 col-md-12  fs-5 ">
                                    <h5 class="text-start" style={{ color: "#000" }}>Mobile GAME DEVELOPMENTSERVICES</h5>
                                    <p> We are a leading game development company with our prime focus on providing entertaining and engaging gaming solutions to clientele around the world. Our development process is precise and well organized to ensure the timely delivery of projects.</p>
                                </div>
                                <img src={Images.m1} alt=' image' class=" img-fluid float-right w-50 ms-5 mb-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h1 class=" text-center my-4" style={{ color: "#000" }}>PC Games</h1>
                        <div class="container ">
                            <div class="row player-wrapper">
                                <div class="col-lg-7 col-md-12  mb-4">
                                    <ReactPlayer className="react-player" url="https://youtu.be/vrLO0ao1Yks?" width='100%' />
                                </div>
                                <div class="col-lg-5 col-md-12  fs-5">
                                    <h5 class="text-start" style={{ color: "#000" }}>PC GAME DEVELOPMENTSERVICES</h5>
                                    <p class="text-justify"> PC video games remain the most popular in the world, so we constantly refine our knowledge and skills in PC game development. Our team has the necessary expertise to offer you such services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Games