import React from 'react'
import Images from "../../Assets/Images/index";

export default function About() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br /> <br /> <br />
                        <h2 class="fw-bold text-center mt-2 mb-2"> About Us</h2>

                        <img src={Images.Sir5} alt=' image' class="rounded img-fluid float-end ms-3 mb-2  w-30 h-20" />
                        <p class="fs-5 text-justify ">
                            For the company founder SM Mahabub Alam, MassiveStar Studio is the culmination of a long and successful career in Information Technology and business consulting arena. Having worked as a Solution Developer in Perspective Computers, Software Engineer in Prime Computers Ltd, Solutions Specialist in Thakral Information Systems (Pvt.) Ltd, Education Consultant in Beximco System Ltd, Chief Executive Officer in Win-Com Software (Pvt.) Ltd, Head of Software in Information Solutions Ltd. (ISL), Chief Technical Officer in Ethics Advanced Technology Ltd. (EATL), Director of Business Development & Engineering in ReliSource Technologies Ltd, and Group CEO of Daffodil Group, he ventured into his own business with a vision to break new grounds in game development for Bangladesh. These young minds are dedicated and devoted with a dream to build a positive Bangladesh where things will come in massive way by following blue ocean strategy. The actual journey in IT started in the year 1999 through WinCom Software Ltd., the pioneer in healthcare, patient management and industrial process management software in Bangladesh. <br /> <br />
                            <span class="fw-bold fs-4 "> Our Vision & Mission:</span> <br />
                            To innovate, create, evolve and deliver the most dream-based and emotionally engaging IT product in the world. <br /> <br />
                            To develop massive manpower with international standard to build innovative, creative and interactive software along with a complete ICT industry in Bangladesh.   <br /><br />
                            <span class="fw-bold fs-4 ">Current Education Projects:</span>
                            <ul>
                                •	“Education through Gaming” - an initiative whereby our new generation will be able to know our history, liberation war, our heritage and culture. At the same time, two other games will be developed based on (i) Mathematics & Science, and (ii) IT & Game Development. <br />
                                •	“We Make Games” - an initiative to explore the possibilities of development of computer games among young minds studying in different levels of schools, colleges, and universities. The objective of the program is to transform the computer games-player to be games-developer, making the young generation future-focused, IT savvy, globalized, and finally to nurture them to be experienced technology professionals and entrepreneurs.
                            </ul>
                            <span class="fw-bold fs-4 ">Our Technical Strength  </span> <br /> <br />
                            <span class="fw-bold fs-4 ">Technology Skills  </span> <br />
                            In last few years, we have built a strong foundation and skills in different technologies. We have developed several projects in the technologies listed below and we are confident to deliver clean and high quality code along with complete ICT solutions.   <br />  <br />
                            <span class="fw-bold fs-4 ">Our Technical Expertise  </span> <br />
                            PHP; LAMP (Linux, Apache, MySQL, PHP,); Cake PHP, Smarty, Joomla, Mambo, X-Cart Gold;  CRE Loaded, phpBB, Word press, Web Baker; Java (J2EE /J2ME); Web Framework: Wicket, Struts, JSP, GWT, Ajax; Application Framework: Spring; ORM Framework: Hibernate, JPA ; Desktop Framework: SWT; Security System: Acegi; Reporting tools:  Jasper Report, Free Chart; Web & Application Server: Tomcat, Web Sphere, BEA Web Logic; Multimedia – Flash , Adobe , Adobe Photoshop; Ruby On Rail; Database: MS SQL Server, MySQL, Oracle; OLAP (On-Line Analytical Processing); Dot Net.  <br /><br />
                            <span class="fw-bold fs-4 ">Our Management Expertise  </span> <br />
                            Developing Business Plan; Assessment of Client Requirements; Product Development; Project Management, Vision and Strategy; Scheduling Tasks and Milestones; Solutions Development; Client Server System Design; Interfacing with Third-Party Vendors; Initial Feasibility Analysis; Conceptual Design; Ensuring Solution Interface; Process Automation; Continuous Process Improvement. <br /><br />
                            <span class="fw-bold fs-2 ">Offered Services: </span>Application Development <br />
                            <span class="fw-bold fs-4 ">Using a customized approach:   </span> <br />
                            Using a customized application development approach, MassiveStar uses technology to create powerful solutions that are focused on such critical areas as enhancing customer relations and increasing revenue opportunities. We focus on building applications using software components which reduce cost and time of development. Our solutions include Client-Server Application Development and Browser Based Applications. This approach involves structured and managed requirements definition process, process-oriented development methodology, strong focus on quality and rigorous program management processes. Also our strong project planning and management processes reduce operational and other business risks. <br /><br />
                            <span class="fw-bold fs-4 ">Maximizing your Return on Investment (ROI):  </span> <br />
                            Our stringent quality procedures clubbed with benchmarked practices and experienced delivery skills help clients get maximum return on their IT spending. Our custom solutions and applications address aspects of the business process conspicuously different to your business and also business processes for which solutions are unavailable in the market.  <br /> <br />
                            <span class="fw-bold fs-4 ">Application management </span> <br />
                            Our application management process is conspicuously different. We thoroughly document your application and build up a knowledge base to complement or improve upon your existing application. Our application management and maintenance processes ensure that your systems are flexible and evolve with your business. This approach enables you to meet customer commitments. <br /> <br />
                            <span class="fw-bold fs-4 ">Keeping you informed throughout the process </span> <br />
                            We constantly measure our performance by providing regular project status reports, to show how well we are meeting our quality, service level, and cost targets - and then use this process as a tool for continuous improvement. Our dedicated product support team provides round-the-clock support, procedural manuals and ensures maximum application uptime. <br /> <br />
                            <span class="fw-bold fs-4 ">Web & Graphic Design </span> <br />
                            Our creative process begins with formative research that determines a baseline for concept, development, and is reflected in the final design. We realize that the motto “Customer Comes First” not only serves to guide our mission statement, it also guides yours. So whether it’s a logo, exhibit, or website you need, we will develop a brand strategy that reflects your goals and objectives while always keeping the perspective of your customer in mind. <br /><br />
                            <span class="fw-bold fs-4 ">Web Application:  </span> <br />
                            Our highly skilled and experienced team of web designers and developers utilize the latest web technologies to create the perfect online presence. We believe in design that is clean and easy to navigate, yet is intuitive, innovative and appealing to your target audience. <br /><br />
                            <span class="fw-bold fs-4 ">E-commerce Solutions </span> <br />
                            E-commerce allows people to create completely new business models. It is a way of doing real-time business transactions, via telecommunication networks, where the customer and the merchant are in different geographical places. Electronic commerce is a broad concept that includes virtual browsing of goods on sale, selection of goods to buy, and payment methods online. <br />    <br />
                            <span class="fw-bold fs-4 ">Dedicated Development Teams</span> <br />
                            MassiveStar offers Dedicated Development Center (DDC) services – a cost-effective way of doing business in today’s IT world. A DDC is a software engineering center for those who are looking for cost effective resource solutions with complete control over the development team. Working under this model, we are able to reserve infrastructure and resources exclusively for the customer for a predefined time frame whereby you have total access, control and transparency over the team to service your IT needs. The team functions as an extension of your company, working closely with you, and following your practices and methodologies. <br /> <br />
                            <span class="fw-bold fs-4 ">Legacy Systems Migration </span> <br />
                            MassiveStar can effectively help you migrate to new technologies while extending the life cycle of your existing/legacy systems. We provide support to migrate application legacy systems to client/server architectures, to extend existing applications to the Internet, or to update from a non-relational to a relational database technology. The legacy conversion model involves:    <br />
                            •	Initial analysis to allow sufficient understanding of the existing application functionality. <br />
                            •	Functional decomposition of the existing application.<br />
                            •	Selection of the defined functional components, which should be ported to the new application. This step requires extensive discussion and agreement with the customer.<br />
                            •	Development of the system conversion specification. This comprehensive document in particular indicates change requirements to the original functionality and new required features.<br />
                            •	Development of proper data conversion mechanisms allows identification of and resolution of data inconsistency problems.<br /> <br />
                            <span class="fw-bold fs-4 ">Quality Assurance </span> <br />
                            Understanding the importance of quality is as a key differentiator to business growth. Our Quality Assurance (QA) processes are documented, institutionalized and evaluated, helping us become more effective and efficient with every project we work on. These rigorous quality control and management procedures minimize the number of defects and maximize our customer satisfaction rating. We have developed our own Quality Process which makes our client's software product a reliable, durable and robust entity. Our specialized and dedicated QA team and a high level co-ordination between our QA team and client's development team ensure the quality of our products. <br /><br />
                            <span class="fw-bold fs-5 text-primary">Our Progress on Gaming and Brain Engineering: What we have done so far </span> <br /> <br />
                            <img src={Images.H2} alt=' image' class=" img-fluid float-right ms-3 mb-3  w-75" />
                            <ol>
                                <li>Through “We Make Games” 14,500 students from 18 institutes came under the network. They got a primary level of games development training. Among them we got good number of good developers as well.</li>
                                <li>A branding has been established among corporate through corporate training. There are 900 people from many corporate houses already receive our training on NLP, Leadership and Attitude program.</li>
                            </ol>
                            <img src={Images.H3} alt=' image' class=" img-fluid float-right  mb-3  w-100" />
                            <img src={Images.H4} alt=' image' class=" img-fluid float-right  mb-3  w-100" /> <br /> <br />
                            <span class="fw-bold fs-4">Corporate Market Development</span> <br />
                            (a) Entrepreneurship development along with games among advance level students at different universities. (b) Corporate market development for games and games based creative products like customized games, gamification and testing & learning games, animation, branding and promotion materials etc. That days are not very far when corporate advertisement will jump into the games also. (c) Education through games (d) Games, interactive animation, 3D architecture modeling, augmented reality outsourcing development (e) Professionalism, positive mentality and attitude development which will be the basement for future international gaming industry in Bangladesh (f) We are developing brain technology to enhance brain power and creativity as well, that will be the core area of excellence in <br />
                            <img src={Images.H5} alt=' image' class=" img-fluid float-right  mb-3  w-100" />
                            future. The strategy is, for employees training, create entrepreneurs and to get corporate access we have developed a Business institute.  <br /> <br />
                            To train our own people we have developed our own institute, for the 1st time in Bangladesh a training institute started to build their own people. Games in an innovative and creative work, so that we have developed our own curriculum and management technology to enhance brain power, attitude and performance. <br /> <br />
                            <span class="fw-bold fs-4">Corporate Relationship for the Mega Games Project</span> <br />
                            <img src={Images.H6} alt=' image' class=" img-fluid float-right  mb-3  w-100" /> <br />
                            Corporate relationship is a basic part of the program as they are the secondary customers of the games product. Extensive relationship has been established with corporate and MNC’s by organizing Workshop and Training with our own research outcome, ROS (Research Outcome Session).   <br /> <br />
                            {/* <span class="fw-bold fs-4">We have developed institute to train our own people:</span> <br />
                            <img src={Images.H7} alt=' image' class=" img-fluid float-right  mb-3  w-100" /> <br /> */}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

