import React from 'react'
import Images from "../../../Assets/Images/index";

function BusinessProcess() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div class="col-12 ">
                        <br></br><br></br>
                        <h1 class=" text-center mt-5 mb-4" style={{ color: "#FE7E00" }}>BUSINESS PROCESS & CHANGE MANAGEMENT:</h1>
                        <img src={Images.business} alt=' image' class="img-fluid w-100 mb-4" />
                        <p class="fs-5 text-justify">
                            <span class="fw-bold">In a fast changing market environment the task of reducing the downtime for change management of business processes has high importance.</span>
                            In a business environment changes are an ever occurring phenomenon. For example, agility of the market, new client needs, or organizational restructuring affects the way of doing business and hence do influence the design of business processes. Adaptability to changes and speed of innovation are a prerequisite for business process management. Hence, a business process management approach should be able to accommodate these changes in the model as and when they occur. Business processes are supported by IT. In case of changes in business it must be ensured that IT is still aligned with busin

                            Change Management Team provides readiness assessments, communications, and training, post-implementation support planning and stakeholder/sponsorship activities from a project’s inception through post-deployment.<br></br>

                            Business Change Management team will manage and monitor fundamental elements of a change caused by a new system or project.<br></br>
                            <span class="fw-bold">We follow the model here:</span><br></br>
                            Awareness:<br></br>
                            <ul>
                                <li>Understand need for the change;</li>
                                <li>Understand nature of the change;</li>
                            </ul>
                            Desire<br></br>
                            <ul>
                                <li>Support the change;</li>
                                <li>Participate and change;</li>
                            </ul>
                            Knowledge<br></br>
                            <ul>
                                <li>How to change;</li>
                                <li>Implement new skills and behavior;</li>
                            </ul>
                            Ability<br></br>
                            <ul>
                                <li>Implement the change;</li>
                                <li>Demonstrate the performance;</li>
                            </ul>
                            Reinforcement<br></br>
                            <ul>
                                <li>Sustain the change;;</li>
                                <li>Build a culture and competence around the change;</li>
                            </ul>
                            <span class="fw-bold">Services:</span><br></br>
                            <ul> <li>Set the strategy;</li>
                                <li>Change management in individual and organization;</li>
                                <li>Managing change in customer; </li>
                                <li>Defining new business process and ensuring implement and improving it where find opportunity;</li>
                                <li>Business Analytics;</li>
                                <li>Enterprise solution.</li>
                            </ul>

                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessProcess