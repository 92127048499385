import React, { useState } from 'react';
import Images from "../../Assets/Images/index";
import { Viewer } from '@react-pdf-viewer/core'; // install this library
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; // install this library
import AIUB from './AIUB.pdf';

function BrainEngineeringAIUB() {
    const [defaultPdfFile] = useState(AIUB);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br /> <br /> <br />
                        <div class="tab-content">
                            <div class="tab-pane container active" id="home">
                                <h2 className="text-center mt-2" style={{ color: "#FE7E00" }}> 1st National Conference on Brain Engineering</h2>
                                <h3 className="text-center">“Leaders Conference on Brain Engineering” </h3>
                                <img src={Images.AIUB1} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <p class="fs-5 text-justify">
                                    On Saturday, 13th October, 2018 AIUB and MassiveStar Studio Ltd jointly organized the very first national Leaders conference on Brain Engineering. The daylong session was held in AIUB campus in Kuril, Dhaka. The Vice Chancellor of AIUB, Prof. Dr. Carmen Z. Lamagna, welcomed the distinguished guests. The key speaker was Mr. SM. Mahabub Alam, Managing Director, MassiveStar Studio Ltd. & President, Brain Engineering Forum. He captivated an audience of Marketing directors and general managers from 100 over reputed organizations including ACI Group, Nitol Motors, Getco, Meghna Group etc. Mr. Mahabub disseminated knowledge of Brain Science, Neuroplasticity, Neuromarketing, Neuromanagement and its impact on management and marketing processes, Brain Engineering at BOS (Blue Ocean Starategy), the Science of Perception and Biology of Management. Prof. Dr. Tabin Hansan, Chairman, CSE, AIUB was the convener and welcome addresses were delivered by Prof. Dr. Tofazzal Hossain, Pro VC, College of Business Administration, AIUB. In addition, an introduction to the research initiatives in interactive software development for learning, design of biocompatible prosthesis and future prospects of research was presented by Dr. Sajib. <br /> <br />
                                    The presentations were followed by a panel discussion chaired by Prof. Dr. Carmen Z. Lamagna and included eminent bureaucrats, vice chancellors, professors, researchers and senior corporate executives. The chief guest of the program was Mr. Shyam Sunder Sikder, Honorable Secretary, Posts and Telecommunications Division, Government of the People's Republic of Bangladesh. The Special Guest was the program were Prof. Dr. Abdur Rab, Vice Chancellor of IUBAT; Prof. Dr. ANM Meshquat Uddin, Vice-Chancellor, Southeast University; Prof. Dr. Anwar Hossain, Vice-Chancellor, Northern University Bangladesh. The speaker of the program were Mr. Rshadul Islam, Additional Secretary, ICT Division; Mr. Musharrof Hossain, President at Asia Pacific Federation of Human Resources Management, Head, Human Resources Management at ICDDR; Mr. Wares Habib, Chief Operating Officer, PRAN-RFL Group; Mr. Aman Ashraf Faiz, Managing Director at GTV; Mr. Quamrul Hasan, Director marketing, ACI Group and Mr. Delower Hossain Raza, Chairman, GanBangla TV. The Session Chaired by Dr. Carmen Z. Lamagna, Vice Chancellor, American International University-Bangladesh (AIUB). <br /> <br />
                                    The program was concluded with a crestgiving ceremony and promise of collaboration among the universities, government organizations, corporate bodies and AIUB Researchers were present the event. <br />
                                </p>

                                <img src={Images.AIUB4} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.AIUB7} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.AIUB8} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.AIUB9} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <h2 className="text-center">Technical session </h2>
                                <img src={Images.AIUB11} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.AIUB10} alt=' image' class="img-fluid mt-3 mb-3  w-100" />

                                <img src={Images.AIUB12} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.AIUB13} alt=' image' class="img-fluid mt-3 mb-3  w-100" />


                                <img src={Images.AIUB2} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.AIUB3} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.AIUB5} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                                <img src={Images.AIUB6} alt=' image' class="img-fluid mt-3 mb-3  w-100" />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col">
                        <br /> <br />
                        <h2 className="mb-3 text-bold text-center">We are in Media </h2>
                        {defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div>
                <br /> <br /> <br /> <br /> <br />

            </div >
        </>
    )
}

export default BrainEngineeringAIUB