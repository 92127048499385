import React, { useState } from 'react'
import { Viewer } from '@react-pdf-viewer/core'; // install this library
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; // install this library
import DailyStar from './DailyStar.pdf';
import DhakaTribune from './DhakaTribune.pdf';
import Canvas from './Canvas.pdf';
import ProthomAlo from './ProthomAlo.pdf';
import ProthomAlo2 from './ProthomAlo2.pdf';
import Kalerkantha from './Kalerkantha.pdf';
import PeoplesTime from './PeoplesTime.pdf';
import VideoGame from './VideoGame.pdf';
import HiFiPublic from './HiFiPublic.pdf';
import MassiveJuddho71PressCoverage from './MassiveJuddho71PressCoverage.pdf';
import ReactPlayer from 'react-player';

function Media() {
    const [defaultPdfFile] = useState(DailyStar);
    const [defaultPdfFile2] = useState(DhakaTribune);
    const [defaultPdfFile3] = useState(Canvas);
    const [defaultPdfFile4] = useState(ProthomAlo);
    const [defaultPdfFile44] = useState(ProthomAlo2);
    const [defaultPdfFile5] = useState(Kalerkantha);
    const [defaultPdfFile6] = useState(PeoplesTime);
    const [defaultPdfFile7] = useState(VideoGame);
    const [defaultPdfFile8] = useState(HiFiPublic);
    const [Juddho71] = useState(MassiveJuddho71PressCoverage);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (

        <>
            <div className="container ">
                <br /> <br /> <br />
                <div className="row player-wrapper ">
                    <h1 class=" text-center  mb-4 " style={{ color: "#FE7E00" }}>We Are In Media</h1>
                    {/* <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=ig0PE_Vsvt8?" width='100%' />
                    </div> */}
                    <div class="  col-lg-6  col-md-12     ">
                        <ReactPlayer className="react-player" url="https://youtu.be/L92F-kiyWfs?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=HUkJiJyZKAE?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=S16-bzd14Ig&t=14s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=uQqrpYvgBZo&t=5s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=80Grrc7g0pk?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=YZnkJeIiM3g?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=80Grrc7g0pk?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=YZnkJeIiM3g?" width='100%' />
                    </div>

                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=uvT-A4dDbRw?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=kPVuGg0PF60?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=63DjykgjnJI?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=Di4IPVXqf7E?" width='100%' />
                    </div>
                    {/* HatirJheel */}
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=MqqhQCT2p0A&t=27s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=hht-lbxTEVg&t=10s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=umEMGK8puAE?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=VkJODqCco-U?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=3SAAnvd8kFI?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=6JW9-EQJHn0?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=TBmJK6IPzy0&t=3s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=o1q48E6DAs4?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=OFCEWLiP1rc?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=QHYg-rTru1I&t=2s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=obAT0KvzF94?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=vrLO0ao1Yks&t=16s?" width='100%' />
                    </div>
                    {/* Mahbub Alam */}
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=KCaMlUH50ro?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=fkWDBqifhMA?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=rwfEu1YoVyE?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=eHeGfAVQNIU?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=WpnMfN93vbA?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=5m8S5LfONzc?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=xVIfnBmWSNg?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=yXNbyXdXqyU?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=_hx-j--tjwc?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=sPre_Cmhun0&t=2s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=l-suWSczzPw&t=147s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=uBlOv76NWsw&t=14s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=tQ7i4rwjnpc?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=tcfXO5QlUWI?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=HufnE50bfLQ?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=_VVrm_CdNKU&t=261s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=okWpZPsSmR8?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=g3u4iaEVH0k?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=DggihubhMoM?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=UUZorz61OLM?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=sXBPwUEeMnE?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=52-kSCsUpKw?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=NqNZknDuChs?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=a3kgEDZ8TnQ?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=i5kqhSe6a_A?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=HeRQUlE_aYE?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=5ne5GJZeSHc?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=RXvs_r87EHQ?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=-obZyiGu9Dk&t=6s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=x8yFiqphlpg?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=wLQfr8YutXU?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  mb-4 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=5bDyuFgo7bU?" width='100%' />
                    </div>

                </div>
                <br />
                <h1 class=" text-center mt-2 mb-4 " style={{ color: "#FE7E00" }}>Media</h1>
                <span class="fs-3 fw-bold">We are in media <br />The Daily Star</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br />
                <a class="btn btn-danger" role="button" href={DailyStar}
                    download="The_Daily_Star">
                    Download
                </a><br></br><br></br>
                <span class="fs-3 fw-bold">Dhaka Tribune</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile2 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile2}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={DhakaTribune}
                    download="DhakaTribune">
                    Download
                </a> <br />
                <span class="fs-3 fw-bold">Canvas</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile3 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile3}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={Canvas}
                    download="Canvas">
                    Download
                </a> <br />
                <span class="fs-3 fw-bold">Prothomalo</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile4 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile4}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={ProthomAlo}
                    download="ProthomAlo">
                    Download
                </a> <br /><br />
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile44 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile44}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={ProthomAlo2}
                    download="ProthomAlo2">
                    Download
                </a> <br />
                <span class="fs-3 fw-bold">Kaler Kantho</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile5 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile5}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={Kalerkantha}
                    download=" Kalerkantha">
                    Download
                </a> <br /><br />

                <span class="fs-3 fw-bold">PeoplesTime</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile6 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile6}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={PeoplesTime}
                    download="PeoplesTime">
                    Download
                </a> <br /><br />

                <div className="row ">
                    <div className="col">
                        {defaultPdfFile7 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile7}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={VideoGame}
                    download="বাংলাদেশের-প্রথম-কম্পিউটার-ভিডিও-গেম-সিরিজ-হাতিরঝিল">
                    Download
                </a> <br />
                <span class="fs-3 fw-bold">Resource</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile8 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile8}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={HiFiPublic}
                    download="News-Hatirjheel-Dream-Begins_-Not-Just-A-Game-_-HiFi-Publi">
                    Download
                </a> <br />
                <div className="row ">
                    <div className="col">
                        {Juddho71 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={Juddho71}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br />
                <a class="btn btn-danger" role="button" href={Juddho71}
                    download=" Juddho71">
                    Download
                </a> <br /><br />

            </div>
        </>

    )
}

export default Media