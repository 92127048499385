import React from 'react'
import Images from "../../Assets/Images/index";

function MobileGame() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 mt-3 mt-3 mb-3 text-center ">
                        <br /><br />
                        <h1 class=" mt-3 3 mb-3 text-center mt-4  mb-0" style={{ color: "#FE7E00" }}> Mobile Games</h1>
                        <img src={Images.m1} alt=' image' class="mt-4 img-fluid  me-5 mb-3  mw-100 " />
                        <a href="https://play.google.com/store/apps/developer?id=MassiveStar+Studio+Limited" class="btn btn-primary btn-lg mt-2" role="button" aria-pressed="true">Download Games</a>
                    </div>

                </div>
                <div className="row">
                    <div className="  col-md-12">
                        <br />
                        <h2 className="mb-3 text-center " style={{ color: "#FE7E00" }}>Arc Welding</h2>
                        <img src={Images.m2} alt=' image' class="mt-4  img-fluid w-100 " />
                        <img src={Images.mm2} alt=' image' class="mt-4  img-fluid w-100 " />
                        <img src={Images.mm3} alt=' image' class="mt-4  img-fluid w-100 " />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            FPS players travel the environment and find the Arc Welding Lab making scene indicator. After reaching the factory lab they found different types of operations and levels. After successfully completing the level they back to the arc welding factory where they will find the indicator for searching for the next level indicator. After completing all the levels player will go back where started.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}>Ball Drop</h2>
                        <img src={Images.m3} alt=' image' class="mt-4 img-fluid w-100 " />
                        <img src={Images.mm3} alt=' image' class="mt-4  img-fluid w-100 " />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br /> Only top on the silver board balls falling for gravity. Outside Balls will be destroyed automatically. The most important thing is which ball can touch the ground after rolling on the board that ball can generate a point from 0 to 9. This point will be added to the scoreboard until reaches 30 of the total score.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3  mb-3 text-center " style={{ color: "#FE7E00" }}> Bagaduli'r Duli</h2>
                        <img src={Images.m5} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm5} alt=' image' class="mt-4  mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            How to play? <br />
                            📏 Use the stick to launch balls or dice one at a time<br />
                            🏀 There are five balls or dices at every level<br />
                            ⇅ You can move the stick by clicking the shot button on the screen<br />
                            💯 You will get points depending on the holes or areas in which the balls end up
                        </p>
                    </div>

                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}> Danguli'r Guli</h2>
                        <img src={Images.m7} alt=' image' class="mt-4 float-left img-fluid w-100 " />
                        <img src={Images.mm7} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100 " />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break mt-4 fs-3">About this game: </span> <br />
                            Danguli'r Guli -Tip Cat is an ancient game with an origin over 2500 years ago. Danguli'r Guli is believed to be the origin of Western games such as Cricket, Baseball, and Softball. <br />
                            This game is played with two sticks, a larger one called bat, which is used to hit the smaller ones, the cat. <br />
                            On Click Easy Control: <br />
                            You only use click-on buttons to control the Cat in the game. The more accurate you click to hit the Gilli, is more likely it is that you will hit the Cat Further. <br />
                            The rule is simple. Click on the button with the same feeling in which you would swing the bat in real life. Think of your finger as a Bat, and click it with speed and accuracy. <br />
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}> Glass Chur Chur</h2>
                        <img src={Images.m8} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm8} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            How to Play? <br />
                            There are two virtual joysticks for accordingly control player movement and camera. Touch anywhere on the screen player will through a ball, which can break some breakable props. Breaking one by one prop increase the score until reaches the target score.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}> Hungama in Airport</h2>
                        <img src={Images.m9} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm9} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            Hungama in Airport is a strategy and action game focused on airport management and hijackings. Become a pilot while flying an airplane and become a soldier while airplane hijacks many more things accurately rendered in realistic 3D. <br /><br />
                            - Partial airport traffic simulation <br />
                            - Flying airplane one place to another<br />
                            - Drive car<br />
                            - Flying Helicopter<br />
                            - Join a Battlefield<br />
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}> Hand Tools Power Tools</h2>
                        <img src={Images.m10} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm10} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            FPS players travel the environment and find the lab. After finding the lab they will find different types of operations and levels for hand tools and power tools. Players will test their knowledge through quizzes once the tour is over and the knowledge acquisition is complete. The quiz will have markings for correct answers and life will be reduced for the wrong answers. This way players can participate in the quiz as many times as they want and continue learning.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}> Kumir Danga</h2>
                        <img src={Images.m11} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm11} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <img src={Images.mmm11} alt=' image' class="mt-4 mb-3 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            This is a story-based game. Where a crocodile eats fish in the water. Then when he is more hungry he attacks the people in the water to the next level. In this way, the crocodile has to cross one level after another to satisfy his hunger. After passing a certain score, the crocodile can go from one level to another. This is how the crocodile adventurer continues. And to get to the final level, a shooter has to kill crocodiles to save people.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-2 text-center " style={{ color: "#FE7E00" }}> Kick Your Boss</h2>
                        <img src={Images.m12} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm12} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            Your dream has come true. All your life you have wanted to kick your boss, and now you finally can. This is a stress relief game. Your boss knows that you are playing this game, and there is nothing he can do about it. <br /><br />
                            → Boss comes in many forms. Different levels different forms! <br />
                            → Get a satisfying reaction to all of your intense Punching<br />
                            → Funny one-liners: Don’t laugh too loud at work<br />
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}> Love Letter</h2>
                        <img src={Images.m13} alt=' image' class="mt-4 mb-3 float-left img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            Love letter writing is a very tough task. After many mistakes, an individual can write unbosom. Every mistake makes paper wastes. Make the paper crumpled ball. Throw the ball into the bin which gives you fun. <br /> <br />
                            Love letter will have you doing just that, aiming a paper ball down a wastebasket. With simple, yet addictive mechanics, this one is a slam dunk for simple, fun games.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}>Mind Crash</h2>
                        <img src={Images.m15} alt=' image' class="mt-4 mb-3 float-left img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            How to play? <br /><br />
                            1) Select a rotating pillar <br />
                            2) Stop rotation when selected pillar near to the destination object
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}> Crash Inside</h2>
                        <img src={Images.m6} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            Touch the screen to spawn the balls.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}> Lathe Machine Operation</h2>
                        <img src={Images.m14} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm14} alt=' image' class="mt-4 mb-3 float-left img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            This is an educational game. Here in the gameplay first the player will be introduced to different types of lathes and later different operations of lathes will be shown. Then there will be some tasks that the player has to complete by participating in various quizzes or operations.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}>Measurement equipment</h2>
                        <img src={Images.m16} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm16} alt=' image' class="mt-4 mb-3 float-left img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            It is a mix of games 3D and 2D. The game is based on the technical measurement of technical education. Gameplay: The first-person player will first take a tour of the environment to gain knowledge about measurement equipment and, over time, quiz on acquired knowledge. In this way, he will go from one level to another and complete the game.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}> Plane Flying</h2>
                        <img src={Images.m17} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm17} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            This is a 3d adventure-type plane flying game. The player will fly in the sky with the plane. The sky is paved with certain coins, the player will collect those coins while flying. Then the plane will have to land safely at the next airport then the game will be complete. The game will not be complete if you do not land at the right airport.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3  mb-3 text-center " style={{ color: "#FE7E00" }}> Plane Parking Management</h2>
                        <img src={Images.m18} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm18} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            This is a 3d game where the plane will be in a random place at the airport. From there the plane has to be taken to the parking spot to complete this game.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3  mb-3 text-center " style={{ color: "#FE7E00" }}> Swing Master Indoor</h2>
                        <img src={Images.m19} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm19} alt=' image' class="mt-4 mb-3 float-left img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            Goal: The main goal of our game is to show people the beautiful scenery as well as to get rid of all their frustration and depression by swinging. And we know kids have a lot of fun playing swing. <br />
                            Skills: Some skills will be needed to possess to master the game. Balancing the speed and accuracy when a player will be swinging is most important. A score will be added if you can cover a certain distance by keeping balance. By scoring in this way, a player will be able to complete the level
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}>Swing Master Outdoor</h2>
                        <img src={Images.m20} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm20} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            Goal: The main goal of our game is to show people the beautiful scenery as well as to get rid of all their frustration and depression by swinging. And we know kids have a lot of fun playing swing. <br />
                            Skills: Some skills will be needed to possess to master the game. Balancing the speed and accuracy when a player will be swinging is most important. A score will be added if you can cover a certain distance by keeping balance. By scoring in this way, a player will be able to complete the level.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}> Sentence Making In Tree House</h2>
                        <img src={Images.m21} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm21} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            FPS players travel the environment and find the sentence-making scene indicator. After reaching the indicator player will find a sentence-making Puzzle. If the puzzle solves correctly then the score will be added and if the puzzle is wrong then scores will be reduced. After successfully completing the level they back to the previous environment where they found the indicator for searching for the next level indicator.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className=" mt-3 mb-3 text-center " style={{ color: "#FE7E00" }}>Technical Drawing</h2>
                        <img src={Images.m22} alt=' image' class="mt-4 float-left img-fluid w-100" />
                        <img src={Images.mm22} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            This is an educational game made up of a combination of 3D and 2D. This game is one of the drawing games in the game series made for the students of Technical Board. In the game, first, the students are taught in different ways and at the end, the students are tested in different ways through quizzes and tests. And the score of the students will be seen so that they can prepare better. If you can finish all the levels in this way, the game will end.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <h2 className=" mt-3  text-center mt-3" style={{ color: "#FE7E00" }}> Wild Hunting</h2>
                        <img src={Images.m23} alt=' image' class="mt-4 img-fluid float-left w-100" />
                        <img src={Images.mm23} alt=' image' class="mt-4 mb-3 float-right img-fluid w-100" />
                        <p class="fs-5 pt-4 text-justify">
                            <span class="fw-bold text-break fs-3">About this game: </span> <br />
                            Time to gather your chasing equip, stack a weapon, hone your senses and track wild creatures in realistic chasing areas. Became to be the genuine hunter and reply to the call of the wild! <br /> <br />
                            Are you ready for an ultimate hunting experience? <br />
                            Are you into visiting new and stunning hunting locations and shooting realistic wild animals? <br /> <br />

                            WILD HUNTING FEATURES: <br />
                            → Hunt crocodiles or other wild animals. <br />
                            → Go hunting in the woods, mountains, forests
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileGame