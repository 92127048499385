import React from 'react'

function ItTransformation() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div class="col-12 ">
                        <br></br><br></br>
                        <h1 class=" text-center mt-4 mb-2" style={{ color: "#FE7E00" }}>IT TRANSFORMATION</h1>
                        {/* <img src={Images.business} alt=' image' class="img-fluid w-100 mb-4" /> */}
                        <p class="fs-5 text-justify">
                            <span class="fw-bold">IT Transformation:</span><br></br>
                            Businesses across the globe are facing dynamic market challenges like never before.  So organizations have to confront the following situation:<br></br>
                            <ul>
                                <li>Line up the IT systems to business goals, to be able to adapt effectively to meet the dynamic business demands;</li>
                                <li>Ensuring an agile, updated and flexible IT infrastructure;</li>
                                <li>Define the organization structure, accountability and decision rights;</li>
                                <li>Maximize the value delivered by IT assets;</li>
                                <li>Shift of Organizations role to a business enabler;</li>
                                <li>Measure the performance of IT.</li>
                            </ul>
                            <span class="fw-bold">Services we Offer:</span><br></br>
                            Our services will address the following key areas:<br></br>
                            <ul>
                                <li>IT Strategy Roadmap,</li>
                                <li>IT Services Strategy,</li>
                                <li>IT Sourcing Strategy,</li>
                                <li>Organizational Structure Definition,</li>
                                <li>IT Skills Assessment,</li>
                                <li>Demand and Portfolio Management,</li>
                                <li>Planning and decision making,</li>
                                <li>Full implementation.</li>
                            </ul>
                            <span class="fw-bold">Enterprise Application Solutions:</span><br></br>
                            The trend of Information Technology has taken a big aspect over the whole world. To achieve high performance with respect to information technology, it’s obvious that a company should contain a well organized computer based information system, containing an application solution that reflects the business strategies, business processes, business models and core processes derived from these strategies.<br></br>

                            In the fast changing global markets, organizations strive to achieve zero latency in the flow of information, and have lower response times to be able to make innovative offerings to the market. Enterprise Application Services and Solutions are a pre-requisite to newer business initiatives and strategies. Enterprise architecture identifies the core components of the organization.<br></br>
                            <span class="fw-bold">ERP:</span><br></br>
                            We developed deep expertise in leading Packaged ERP applications. We have experience in ERP implementation, ERP integration, and extending ERP functionality which has helped us develop capabilities in architecting scalable and adaptive customer solutions, which support incremental enhancement with built-in modularity. As a result, our clients have access to real-time business information that is intelligible and useful across the enterprise.<br></br>
                            <span class="fw-bold">CRM:</span><br></br>
                            For a business organization, customers play a very important role. So, we developed a CRM to create a long term, profitable relationships with the customer. We provide CRM consulting services to help clients adopt appropriate industry-specific CRM best practices, which can be incorporated into scalable packaged applications, hosted solutions, and custom-built applications.<br></br>

                            The basic functionality provided by the CRM section is let the employees see the behavior of the customer and organize their time better, identify opportunity and turn them into close deals, account history is used to check out previously purchased products, to check how the customers satisfy with the level of service or the quality of products, to analyze and making perfect decision.<br></br>

                            Apart from these our software concern E-Tech Ltd. offers various kinds of customized enterprise and e-commerce solutions for the industry like Education, Manufacturing, Health, Banking, Telecommunication, Communication and Technology.<br></br>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ItTransformation