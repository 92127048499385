import React, { useState } from 'react';
import Images from "../../Assets/Images/index";
import { Viewer } from '@react-pdf-viewer/core'; // install this library
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; // install this library
import Research from './Research.pdf';

function BrainEngineeringResearch() {
    const [defaultPdfFile] = useState(Research);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br /> <br /> <br />
                        <h2 className="text-center mt-2" style={{ color: "#FE7E00" }}> 1st National Conference on Brain Engineering</h2>
                        {/* <h2 className="text-center">Research Team of IUBAT</h2> */}
                        <img src={Images.IUBAT5} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                        <img src={Images.IUBAT7} alt=' image' class="img-fluid mt-3 mb-3  w-100" />
                        <img src={Images.E2} alt=' image' class="img-fluid mt-3 mb-3  w-100" />

                        <p class="fs-5 text-justify">
                            <br />
                        </p>

                    </div>
                </div>
                <div className="row ">
                    <div className="col">
                        <br />
                        <h2 className="text-center">Seminar & Research Kickoff</h2> <br />
                        {defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div>
                <br /> <br /> <br /> <br /> <br />
            </div >
        </>
    )
}

export default BrainEngineeringResearch