import React from 'react'
import Images from "../../Assets/Images/index";

function ERP() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br />  <br /> <br />
                        <h2 class="fw-bold text-center mt-2 fs-3" style={{ color: "#FE7E00" }}>ERP Software </h2>
                        <img src={Images.ERP2} alt=' image' class=" img-fluid w-100" />
                        <p class="fs-5 text-justify">
                            ERP software for best optimization of your business processes. It provides Accurate and timely access to reliable information. It distributes information between all components of the organization and take necessary action to transform raw data into valuable information or report. It eliminate unnecessary operations or part of operation by it’s business intelligence and smart design. <br /> <br />
                            Along with the giant firms, small and medium enterprises also need ERP system. According to the need of the customers, modules like Inventory management, Production, Human resource management system, Accounting etc. can be added or removed from our ERP software package in Bangladesh. <br /> <br />
                            <span class="fw-bold text-break fs-3">Our ERP system includes the following features :  </span> <br /> <br />
                            <span class="fw-bold text-break fs-4">Sales and Accounts Receivables </span>
                            {/* <img src={Images.Camp} alt=' image' class="mt-4 img-fluid float-left me-5 mb-3  w-50" /> */}
                            <ul>
                                <li> Sales and Accounts Receivables</li>
                                <li>Open Customer Accounts & Customer Branches</li>
                                <li>Create Sales Areas, Sales Types, Sales Groups, Salesman Groups</li>
                                <li>Create Sales Groups for grouping Customer Branches</li>
                                <li>make Sales Quotations (with inquiry and creating of Sales Orders)</li>
                                <li>Prepare Sales Orders and Goods Delivery Notes</li>
                                <li>Sending Customer Invoices and Allocating Credits</li>
                                <li>All of the Sales files are fully editable and printable</li>
                                <li>Point of sales definitions for better handling of cash sales</li>
                                <li>Customer Payments and Allocations</li>
                                <li>Sales documents with custom branding</li>
                                <li>Dimensions can be selected for all operations</li>
                                <li>Shipping Costs and Legal terms can be added with all invoices</li>
                            </ul>
                            <span class="fw-bold text-break fs-3">Why do you choose us for your ERP System </span> <br /> <br />

                            <ul>
                                Capable of developing the application in localized language (Bengali). <br />
                                Our ERP software is easy to use<br />
                                Capable of creating high volume data manageable ERP software<br />
                                We provide Hybrid data back-up for ERP application.<br />
                                Special security measures for securing data<br />
                                Faster connection for the ERP software<br />
                                You can get one stop solution<br />
                                We provide fast delivery for the clients.<br />
                                We focus on quality<br />
                                24/7 constant support<br />
                                We charge realistic price<br />
                            </ul>

                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ERP