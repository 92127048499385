import React from 'react'
import Images from "../../Assets/Images/index";
function EduNet() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br />  <br /> <br />
                        <h2 class="fw-bold text-center fs-1 mt-2 " style={{ color: "#FE7E00" }}>EduNet</h2>
                        <img src={Images.S1} alt=' image' class="img-fluid   mb-2  w-100" />
                        <p class="fs-5 text-justify mt-3">
                            <span class="fw-bold  fs-4 ">EduNet </span>
                            is an advanced Education Management System in Bangladesh. This is a web-based ERP software. The System comes with several unique features that always gives us an edge over our competitors. Academic Management is one of the unique features by which academy can handle student's admission management Courses & Batch management along with Time-table, Create Academic Event calendar, Manage exams & results, Online admission & attendance and so on. Apart from these features, you can also integrate other ERP modules with that system. Like you can add HRM module to handle HR operations, Accounting Module or you can also add Library module to automate your library management system. As an admin assigned by the school administration, you can access the database and more. Afterward, students can also access the system to know their examination marks and important notices without having editing access. A dynamic website is integrated with this software with various designs. <br /> <br />
                            Like other industries, educational organizations are also embracing technology to ensure smooth and hassle-free management system. This is the time to regulate your academy in a most easier and effective way. MassiveStar has brought Aim software to make your school management process more comfortable. <br /> <br />
                            <h2 class=" text-center fs-3 mt-2 mb-3" style={{ color: "#FE7E00" }}>Features Of Our School Management Software</h2>
                            <ul class="float-left w-50">
                                <li>Dashboard</li>
                                <li>Global Setting</li>
                                <li>Academic Module</li>
                                <li> Online Admissiom</li>
                                <li>Student Admission</li>
                                <li>Timetable Module</li>
                                <li>Attendance Module</li>
                            </ul>
                            <ul class="float-right w-50">
                                <li>HRM & Payroll Module</li>
                                <li>Accounts & Finance Module</li>
                                <li>Results Module</li>
                                <li>Online Learning Module</li>
                                <li>SMS Module</li>
                                <li>Web Management</li>
                                <li>User Managment</li>
                            </ul>
                            {/* <h2 class=" text-center fs-3 mt-4" style={{ color: "#FE7E00" }}>School Management Software Details</h2> <br /> */}
                        </p>
                        <img src={Images.S2} alt=' image' class="img-fluid   mb-2  w-100" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EduNet