import React from 'react'
import Images from "../../Assets/Images/index";

function Customized() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br />  <br /> <br />
                        <h2 class="fw-bold text-center mt-2 " style={{ color: "#FE7E00" }}>Custom Software Development Services</h2>
                        <img src={Images.C3} alt=' image' class="img-fluid mt-32 w-100" />
                        <p class="fs-5 text-justify mt-3">
                            For software development solutions, MassiveStar is the right place. We are well capable of designing and developing quality software products and offering optimum software support services for any start-ups or large scale organization. <br /> <br />
                            Usually, the Software development process is a combination of several activities, such as requirement analysis, planning, designing, implementation or programming, testing, problem or bug fixing, and upgrading.<br /> <br />
                            <span class="fw-bold text-break fs-3">Custom Software Development Services </span> <br />
                            MassiveStar is one of the most reliable and cost-effective software system developers among high-quality software system services. Our custom software development service is best suited to meet all your requirements and needs from budget to timeframe.<br /> <br />
                            Our professional software developers are enough experienced to invent, design, program, scale, and update your software applications and deliver a wide range of services yet. <br /> <br />
                            <span class="fw-bold text-break fs-3">Software Consulting: </span> <br />
                            We offer to consult services on software development for both ongoing and new software projects. We provide consultant support for software design, feasibility study, ideation, PoC, and so on. <br /> <br />
                            <span class="fw-bold text-break fs-3">Custom software development: </span><br />
                            We can invent, design, and program any software based on your business or demand. We are also able to scale and upgrade any ongoing and new software development projects as well. Our professional developers are able to meet your unique complex needs or requirements for any mobile, web, desktop, or cloud software. <br /> <br />
                            <span class="fw-bold text-break fs-3">Software product development:  </span> <br />
                            MassiveStar offers software product development services for your business marketing. Our software development team is well capable of keeping your product functionality development on schedule. <br />
                            We always emphasize to develop a scalable and robust software that must be meet maximum quality standards very well. From maintenance activities and quality assurance of current products to research new products, Tech Dyno BD can help you develop your product life cycle to a great extent. <br /> <br />
                            {/* <span class="fw-bold text-break fs-3">Outsourcing Software development:  </span> <br />
                            We can carry out any software development project on behalf of both small and large enterprises to maintain their digital transformation initiatives and their business growth.  <br /> <br /> */}
                            <span class="fw-bold text-break fs-3">Development team augmentation:  </span> <br />
                            Our software developers and software re-engineering experts have enough experience to handle software capabilities and reduce the lack of internal resources. We can integrate, architects, and assure the quality of a software best.  <br /> <br />
                            <span class="fw-bold text-break fs-3">Software Enhancement and Modernization:  </span> <br />
                            Our developers always keep up to date with the latest tool and technologies. By utilizing the latest technologies, they can continuously update any software to lighten the life cycle of software.  <br /> <br />
                            <span class="fw-bold text-break fs-3">Enterprise Software Development:  </span> <br />
                            We have gained much experience in enterprise software development. With advanced technologies and expertise, we are well capable of bringing every business enterprise solutions through our software.  <br /> <br />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Customized