import React, { useState } from 'react';
import "./Home.css";
import Images from "../../Assets/Images/index";
import ReactPlayer from 'react-player';
// import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function Home() {
  const [text, setText] = useState('');
  // const handleUpClick = () => {
  //     let newText = text.toUpperCase();
  //     setText(newText)
  // }
  const
    handleClearClick = () => {
      let newText = "";
      setText(newText)
    }
  const handleOnChange = (event) => {
    setText(event.target.value)
  }
  return (
    <>
      {/* ------------------------Slider-------------------------------------- */}
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 mt-3">
            <br /><br />
            <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 7"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 8"></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src={Images.Health} class="d-block w-100 " alt="Slideer1" />
                  <div class="carousel-caption d-none d-block mb-5">
                    {/* <motion.h1 animate={{ y: 30 }} class="animated bounceInRight" >HealthNet</motion.h1> */}
                    <h1>HealthNet</h1>
                    <p>The ultimate network of Doctor,Patients,Hospital,Clinic,Pharmacy,Pathological lab,Pharmaceutical company.</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src={Images.VR} class="d-block w-100" alt="..." />
                  <div class="carousel-caption d-none d-block mb-5">
                    <h1>Games, VR & Animation</h1>
                    <p>The ultimate Gamification, Visulization & 3D Experiance impect on real life. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src={Images.FoodNet2} class="d-block w-100" alt="..." />
                  <div class="carousel-caption d-none d-block mb-5">
                    <h1>FoodNet</h1>
                    <p>The way to manage your food business.</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src={Images.EduNet} class="d-block w-100 " alt="..." />
                  <div class="carousel-caption d-none d-block mb-5">
                    <h1>EduNet</h1>
                    <p>The way to manage student and institution at all level.</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src={Images.RetailerNet} class="d-block w-100" alt="..." />
                  <div class="carousel-caption d-none d-block mb-5">
                    <h1>RetailerNet</h1>
                    <p>The way to manage your business ranging Retailer to Wholesalers. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src={Images.ERP} class="d-block w-100" alt="..." />
                  <div class="carousel-caption d-none d-block">
                    <h1>ERP for SME & Manufacturing Unit</h1>
                    <p>The true Enterprise Resource Planning</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src={Images.C3} class="d-block w-100" alt="..." />
                  <div class="carousel-caption d-none d-block mb-5">
                    <h1>Customized Software</h1>
                    <p>Software that fits your busines.</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src={Images.Brain2} class="d-block w-100" alt="..." />
                  <div class="carousel-caption d-none d-block">
                    <h1>Brain Engineering & Artificial Intelligence</h1>
                    <p>The Engineering with brain that will makes future.</p>
                  </div>
                </div>

              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------About US-------------------------------------- */}
      <section id="about" class="about">
        <div class="container">
          <div class="row ">
            <h1 style={{ color: "#FE7E00" }} class="d-flex justify-content-center mt-3 mb-3"> Who We Are</h1>

            <div class="col-lg-7  col-sm-12  ">
              <div class="left">
                <img src={Images.Brain8} alt=' image' class="rounded img-fluid mb-2  w-100 h-75 " />
              </div>
            </div>
            <div class="col-lg-5  col-sm-12 d-flex  ">
              <p class=" text-justify fs-5"> MassiveStar Studio Ltd. is a private company working in the field of Health Care Industry, Food and Resturent Management, Education Management, Retailer Shop Management, ERP for Menufacturing Unit, Customized Software, Games,VR & Animation, Brain Engineering & AI. In software vertical we provide complete solution for Retailers, Wholesalers, SME and Large scale Menufacturing Unit. </p>
            </div>
            <div class="col-12">
              <p class=" text-justify fs-5">MassiveStar started the journey in the year 2013 with a group of young talents under a very strong and experienced leadership. We develops high-quality PC, mobile and online games focused on rich stories based on history, renowned character and write-up from famous writer. MSS is a true combination of experienced leadership and a group of talented young those who are dedicated and fully motivated with a massive dream.</p>
            </div>
          </div>
        </div>
      </section>
      {/* --------------------------------Products-------------------------------------- */}
      <section class="Products" id="Products">
        <div class="container">
          <h1 class=" text-center mt-3 mb-4" style={{ color: "#FE7E00" }}>Our Focus Areas</h1>
          <div class="row small">
            <div class="col-lg-3 col-sm-6 ">
              <div class="card ">
                <img src={Images.Logo2Health} alt='profile image' class="my-2  profile-img" />
                <h1 class=" name mt-3">HealthNet</h1>
                <p class="description">Provide patients with the right information to empower them to manage their health better.Using our app will make your life easier.</p>
                <Link class="p-0 fs-5" to="/products/health-net">View Details</Link>
              </div>

            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card ">
                <img src={Images.LogoVR} alt='profile image' class="my-3 profile-img" />
                <h1 class=" mt-3 name">Games, VR & Animation</h1>
                <p class="description">Your gaming perception can change through the experiences our hyper casual and interactive game development solutions. </p>
                <Link class=" mb-3 mt-3 fs-5" to="/products/game">View Details</Link>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card ">
                <img src={Images.LogoFood} alt='profile image' class="my-2 profile-img" />
                <h1 class=" mt-1  name">FoodNet</h1>
                <p class="description mb-2">Whether they're dining in or ordering out, let customers take control of their dining experience with Lightspeed Order Anywhere.</p>
                <Link class=" mb-5 fs-5" to="/products/food-net">View Details</Link>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card ">
                <img src={Images.LogoEdu} alt='profile image' class="my-4 profile-img" />
                <h1 class=" mt-1  name">EduNet</h1>
                <p class="description">EduNet serves educational institutions’ managerial staff, providing reports and online access to actual performance and historical records.</p>
                <Link class=" mb-3 fs-5" to="/products/edu-net">View Details</Link>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card mt-4">
                <img src={Images.LogoRetailer} alt='profile image' class="profile-img w-75 h-50" />
                <h1 class=" mt-3  name">RetailerNet</h1>
                <p class="description">We provide for you with the best Retail management software in Bangladesh.</p>
                <Link class=" fs-5" to="/products/retailer-net">View Details</Link>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card mt-4">
                <img src={Images.LogoERP} alt='profile image' class="my-1 profile-img w-75 h-50" />
                <h1 class=" mt-1   name">ERP for SME & Menufacturing Unit</h1>
                <p class="description">Automate your business with powerful integrated ERP System.</p>
                <Link class="mt-1 fs-5" to="/products/ERP">View Details</Link>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card mt-4">
                <img src={Images.LogoCustom} alt='profile image' class="profile-img my-4 " />
                <h1 class=" mt-3   name">Customized Software</h1>
                <p class="description">It is the process of designing, creating, deploying and maintaining software. </p>
                <Link class="  fs-5" to="/products/customized-software">View Details</Link>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card mt-4">
                <img src={Images.LogoAI} alt='profile image' class="profile-img my-2" />
                <h1 class=" mt-2 name">Brain Engineering & AI</h1>
                <p class="description">Brain engineering, which unites methods of neuroscience and engineering to investigate and repair neural networks. </p>
                <Link class="  fs-5" to="/brain-engineering">View Details</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ---------------------------------------Cutting Edge Solutions For Your Busines----------- */}
      <section class="Teams" id="Teams">
        <div class="container">
          <h1 class=" text-center mt-5 mb-5" style={{ color: "#FE7E00" }}>Cutting Edge Solutions For Your Busines</h1>
          <div class="row ">
            <div class="col-lg-6 col-md-6 mb-3">
              <div class="card2 border border-warning rounded">
                <img src={Images.Software} alt='profile image' class="my-4 profile-img2  rounded mx-auto d-block" />
                <h1 class=" name">Software</h1>
                <p class="description fs-5"> Common uses for software in everyday life are connected to transportation, school, work, and entertainment.</p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="card2 border border-warning rounded">
                <img src={Images.LogoVR} alt='profile image' class=" profile-img2 rounded mx-auto d-block" />
                <h1 class=" name ">Games, VR & Animation</h1>
                <p class="description fs-5">We run independent projects, jointly build games with the client's team, and provide further operational services.</p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 mt-4">
              <div class="card2 border border-warning rounded">
                <img src={Images.Enterprise} alt='profile image' class="profile-img2 rounded m-4 pb-3 mx-auto d-block" />
                <h1 class=" name">Consultancy</h1>
                <p class="description fs-5">At Consulting, our goals are to help you reach your business targets faster and to increase your competitiveness.</p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 mt-4">
              <div class="card2 border border-warning rounded">
                <img src={Images.LogoAI} alt='profile image' class="my-3 profile-img2 rounded mx-auto d-block w-25 h-25" />
                <h1 class=" name">Brain Engineering</h1>
                <p class="description fs-5">BrainEngineering is a discipline within biomedical engineering that uses engineering techniques to understand, repair, replace, or enhance neural systems.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
      {/*------------------------------------ Top Products , Nav&Tabs -------------------------------------*/}
      <div class="container">
        <h1 class=" text-center mt-5 " style={{ color: "#FE7E00" }}>Top Products</h1>
        <div class="row ">
          <div class="col">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active mt-4" id="pills-Home1-tab" data-bs-toggle="pill" data-bs-target="#pills-Home1" type="button" role="tab" aria-controls="pills-home1" aria-selected="true"><img width="200" height="150" src={Images.LogoHealth} /><figcaption>HealthNet</figcaption></button>
              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link mt-4" id="pills-Home2-tab" data-bs-toggle="pill" data-bs-target="#pills-Home2" type="button" role="tab" aria-controls="pills-home2" aria-selected="false"><img width="200" height="150" src={Images.LogoFood} /><figcaption>FoodNet</figcaption></button>

              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link mt-4" id="pills-Home3-tab" data-bs-toggle="pill" data-bs-target="#pills-Home3" type="button" role="tab" aria-controls="pills-home3" aria-selected="false"><img width="200" height="150" src={Images.LogoEdu} /><figcaption>EduNet</figcaption></button>
              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link mt-4" id="pills-Home4-tab" data-bs-toggle="pill" data-bs-target="#pills-Home4" type="button" role="tab" aria-controls="pills-home4" aria-selected="false"><img width="200" height="150" src={Images.LogoRetailer} /><figcaption>RetailerNet</figcaption></button>
              </li>

              {/* <li class="nav-item" role="presentation">
                <button class="nav-link mt-4" id="pills-Home5-tab" data-bs-toggle="pill" data-bs-target="#pills-Home5" type="button" role="tab" aria-controls="pills-home5" aria-selected="false"><img width="190" height="150" src={Images.LogoAI} /><figcaption>BrainEngineering</figcaption></button>
              </li> */}
            </ul>
            <div class="tab-content text-justify" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-Home1" role="tabpanel" aria-labelledby="pills-Home1-tab">
                <p class=" text-justify fs-5">
                  <h2 class="  fs-3 mt-2 mb-3" style={{ color: "#007BFF" }}>Features Of Our HealthNet Software</h2>
                  i)	Verified doctor<br />
                  ii)	Supper fast support<br />
                  iii)	Health History<br />
                  iv)	Medicine delivered in your door<br />
                  v)	Better consultation quality<br />
                  vi)	Medicine reminder<br />
                </p>
              </div>
              <div class="tab-pane fade" id="pills-Home2" role="tabpanel" aria-labelledby="pills-Home2-tab">
                <p class=" text-justify fs-5">
                  <h2 class="  fs-3 mt-2 mb-3" style={{ color: "#007BFF" }}>Features Of Our FoodNet Software</h2>
                  i)Reduce the order processing time.<br />
                  ii)	Automate redundant work.<br />
                  iii)	Deliver a great customer experience.<br />
                  iv)	Determine Profits & Costs
                </p>
              </div>
              <div class="tab-pane fade" id="pills-Home3" role="tabpanel" aria-labelledby="pills-Home3-tab">
                <p class=" text-justify fs-5">
                  <h2 class=" text-center fs-3 mt-2 mb-3" style={{ color: "#007BFF" }}>Features Of Our School Management Software</h2>
                  <ul class="float-left w-50">
                    <li>Dashboard</li>
                    <li>Global Setting</li>
                    <li>Academic Module</li>
                    <li> Online Admissiom</li>
                    <li>Student Admission</li>
                    <li>Timetable Module</li>
                    <li>Attendance Module</li>
                  </ul>
                  <ul class="float-right w-50">
                    <li>HRM & Payroll Module</li>
                    <li>Accounts & Finance Module</li>
                    <li>Results Module</li>
                    <li>Online Learning Module</li>
                    <li>SMS Module</li>
                    <li>Web Management</li>
                    <li>User Managment</li>
                  </ul>
                </p>
              </div>
              <div class="tab-pane fade" id="pills-Home4" role="tabpanel" aria-labelledby="pills-Home4-tab">
                <p class=" text-justify fs-5">
                  <h2 class=" text-center fs-3 mt-2 mb-3" style={{ color: "#007BFF" }}>Features Of Our Best Retail Management Software</h2>
                  <ul class="  float-left w-50">
                    <li>Sales Management</li>
                    <li> Purchase Management</li>
                    <li> General Accounts Management</li>
                    <li> Products Management</li> <br />
                    {/* <li> Reports Management</li> */}
                  </ul>
                  <ul class="  float-right w-50">
                    <li> Customer Management</li>
                    <li>Suppliers Management</li>
                    <li>SMS Management</li>
                    <li> Taxes Management</li> <br />
                  </ul>
                </p>
              </div>
              {/* <div class="tab-pane fade" id="pills-Home5" role="tabpanel" aria-labelledby="pills-Home5-tab">
                <p class=" text-justify fs-5">
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------Our Motivation, Our Pride-------------------*/}
      <div class="container text-justify">
        <h1 class=" text-center mt-3 mb-3" style={{ color: "#FE7E00" }}>Our Motivation, Our Pride        </h1>
        <h2 class="fw-bold text-center mt-3">“ম্যাসিভ যুদ্ধ৭১” </h2>
        <div class="row">
          <div class="col-12 h-50 "> <img src={Images.Judhho71} alt='profile image' class="rounded img-fluid w-100" /></div>
        </div>
        <div class="row player-wrapper">
          <div class="col-lg-5 col-md-12 pt-3 fs-5 ">
            <p>
              কাগজে গল্প লেখা যায়। এতদিন তাই হয়ে আসছে। চিত্রকরেরা ছবিতে গল্প লেখেন। কেউ কেউ মাটির মূর্তিতে গল্প লিখতে পারেন। কেউবা লোহায়, তামায় বা পিতলে গল্প লিখতে পারেন। সেলুলয়েডের ফিতে মানে সিনেমাতে গল্প লেখার পদ্ধতিও চলছে বহু যুগ ধরে। এসব পদ্ধতির বড় সমস্যা গল্পকার যেমন ইচ্ছা পাঠক বা দর্শককে নিয়ন্ত্রন করেন। এগুলো বড়জোর দ্বিমাত্রিক। আসুন আমরা ত্রিমাত্রিক গল্প দেখি যা দর্শক চাইলে পরিবর্তন করতে পারেন। আর হ্যাঁ এই পদ্ধতির আরও কিছু শুবিধা আছে, এই পদ্ধতির গল্পগুলো একটু মজারও হয়। দর্শকেরা নিছক দর্শক নয় তাই তারা গল্পের মধ্যে ঢুকে যেতে পারে। তাই গল্পের মজাও তারা অনেকদিন ধরে তারিয়ে তারিয়ে নিতে পারে। আগামী দুনিয়া হবে ত্রিমাত্রিক।
            </p>
          </div>
          <div class="col-lg-7 col-md-12 mt-3  ">
            <ReactPlayer className="react-player" url="https://youtu.be/sPre_Cmhun0?" width='100%' />
          </div>
        </div>
        <div class="row player-wrapper">
          <div class="col-lg-6 col-md-12 pt-1  ">
            <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=KCaMlUH50ro?" width='100%' />
          </div>
          <div class="col-lg-6 col-md-12 pt-1  mb-4 ">
            <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=okWpZPsSmR8?" width='100%' />
          </div>
        </div>
      </div>
      {/* -----------------------News and Events------------------------- */}
      <div class="container text-justify  ">
        {/* <h1 class=" text-center pt-4 pb-4" style={{ color: "#FE7E00" }}> News and Events </h1>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <img src={Images.Judhho71} alt='profile image' class="img-fluid w-100  pb-3" />
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita et voluptatem totam? Beatae fuga optio corrupti vitae consequatur dolores quae, architecto hic possimus soluta at tempora sint vero reprehenderit, eius id sunt, sed voluptatibus veniam neque? Eaque sunt rem assumenda accusantium minus. Aliquid accusamus quas in placeat explicabo qui. Eum?</p>
          </div>
          <div class="col-lg-6 col-md-12">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente, ea? Vero id nisi possimus ab at aut reiciendis vitae quaerat doloribus tenetur, quam quisquam. Culpa fuga accusamus, quaerat porro accusantium repellat tempora eaque saepe at earum? Consequatur blanditiis sunt odit totam veniam incidunt amet cupiditate sed eos voluptatum delectus a nulla, temporibus est soluta nihil eligendi vel fugit cumque! Vel laborum eligendi tenetur? Nisi nobis fugit doloribus eum aut? Architecto, fuga soluta temporibus accusamus iste sequi dolorem dignissimos quas labore numquam incidunt ab quaerat quis sit similique iure, nobis reprehenderit, voluptatibus repellendus perferendis! Libero vero mollitia reiciendis eum labore ipsa. voluptatibus repellendus perferendis! Libero vero mollitia reiciendis eum labore ipsa.</p>
          </div>
        </div> */}
        <form class="row g-3 needs-validation" novalidate method="post" action="mailto:mahabubmassive@gmail.com?subject=subject&message=message">
          <h1 class=" text-center mt-5 mb-4 bg-primary" style={{ color: "#FE7E00" }}>Feel Free to Knock Us</h1>
          <div class="col-md-4 col-lg-6 position-relative">
            <label for="validationTooltip01" class="form-label">First name</label>
            <input type="text" class="form-control" id="validationTooltip01" required />
            <div class="valid-tooltip">
              Looks good!
            </div>
          </div>
          <div class="col-md-4 col-lg-6 position-relative">
            <label for="validationTooltip02" class="form-label">Email</label>
            <input type="text" class="form-control" id="validationTooltip02" required />
            <div class="valid-tooltip">
              Looks good!
            </div>
          </div>
          <div class="col-md-4 col-lg-12 position-relative">
            <label for="validationTooltipUsername" class="form-label">Messages</label>
            <div class="input-group has-validation">
              <textarea type="text" class="form-control" value={text} onChange={handleOnChange} id="validationTooltipUsername" aria-descri bedby="validationTooltipUsernamePrepend" required />
              <div class="invalid-tooltip">
                Please choose a unique and valid username.
              </div>
            </div>
          </div>

          <div class="col-12 ">
            {/* <button class="btn btn-dark mb-3 mx-1 " onClick={handleUpClick}>Convart UpperCase</button> */}
            <button class="btn btn-dark mb-3 mx-1 " onClick={handleClearClick}>Clear</button>
            <button class="btn btn-dark mb-3 mx-1" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Home;