import React, { useState } from 'react'
import Images from "../../Assets/Images/index";
import { Viewer } from '@react-pdf-viewer/core'; // install this library
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; // install this library
import DailyStar from './../Media/DailyStar.pdf';
import DhakaTribune from './../Media/DhakaTribune.pdf';
import Canvas from './../Media/Canvas.pdf';
import ProthomAlo from './../Media/ProthomAlo.pdf';
import ProthomAlo2 from './../Media/ProthomAlo2.pdf';
import Kalerkantha from './../Media/Kalerkantha.pdf';
import PeoplesTime from './../Media/PeoplesTime.pdf';
import VideoGame from './../Media/VideoGame.pdf';
import HiFiPublic from './../Media/HiFiPublic.pdf';
import ReactPlayer from 'react-player';

function HatirjheelDreamBegins() {
    const [defaultPdfFile] = useState(DailyStar);
    const [defaultPdfFile2] = useState(DhakaTribune);
    const [defaultPdfFile3] = useState(Canvas);
    const [defaultPdfFile4] = useState(ProthomAlo);
    const [defaultPdfFile44] = useState(ProthomAlo2);
    const [defaultPdfFile5] = useState(Kalerkantha);
    const [defaultPdfFile6] = useState(PeoplesTime);
    const [defaultPdfFile7] = useState(VideoGame);
    const [defaultPdfFile8] = useState(HiFiPublic);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br></br><br></br><br></br>
                        <img src={Images.Dream} alt=' image' class="img-fluid  me-5 mb-3  w-100" />
                        <p class="fs-5 text-justify pt-3 mb-0">
                            <span class="fst-italic fw-bold">Project One</span><br></br>
                            Hatirjheel is a series of games. The first games of this series is Dream Begins, a combination of reality as well as dream. We think Hatirjheel is the prime gift to the new generation and gift to the people of Bangladesh. We feel that the massive development of Bangladesh what so far have done must be projected to the new generation who have deep interest in information technology and dream for future Bangladesh. <br />
                            The present government has started journey with the slogan, “Digital Bangladesh”. A huge development has been done already. Time has come to project the same. With the objective we have developed the game on Hatirjheel, where our local culture, heritage and dream have been reflected. By playing the game our new generation will look at Hatirjheel as the symbol of present and future development of Bangladesh.<br />
                            “Hatirjheel, Dream Begins” will be the first computer series game from an organized group in the country and developed by our local engineers and young talents for the people of Bangladesh.Hatirjheel is a series of games. The first games of this series is Dream Begins, a combination of reality as well as dream. We think Hatirjheel is the prime gift to the new generation and gift to the people of Bangladesh. We feel that the massive development of Bangladesh what so far have done must be projected to the new generation who have deep interest in information technology and dream for future Bangladesh.<br />
                            The present government has started journey with the slogan, “Digital Bangladesh”. A huge development has been done already. Time has come to project the same. With the objective we have developed the game on Hatirjheel, where our local culture, heritage and dream have been reflected. By playing the game our new generation will look at Hatirjheel as the symbol of present and future development of Bangladesh.<br />
                            “Hatirjheel, Dream Begins” will be the first computer series game from an organized group in the country and developed by our local engineers and young talents for the people of Bangladesh.<br /><br />
                        </p>
                        {/* <div class="d-flex justify-content-center mb-4  ">
                            <ReactPlayer className="react-player" url="https://youtu.be/vrLO0ao1Yks?" />
                        </div> */}
                    </div>
                </div>
                <div className="row gy-4 ">
                    <h1 class="text-center mb-3">We are in media</h1><br></br>
                    <div class="col-lg-6 col-md-12   ">
                        <ReactPlayer className="react-player " url="https://www.youtube.com/watch?v=umEMGK8puAE?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=VkJODqCco-U?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=3SAAnvd8kFI?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=6JW9-EQJHn0?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=TBmJK6IPzy0&t=3s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=o1q48E6DAs4?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12   ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=OFCEWLiP1rc?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12  ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=QHYg-rTru1I&t=2s?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=obAT0KvzF94?" width='100%' />
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <ReactPlayer className="react-player" url="https://www.youtube.com/watch?v=vrLO0ao1Yks&t=16s?" width='100%' />
                    </div>
                </div>
                <br />

                <span class="fs-3 fw-bold"> The Daily Star</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br />
                <a class="btn btn-danger" role="button" href={DailyStar}
                    download="The_Daily_Star">
                    Download
                </a><br></br><br></br>
                <span class="fs-3 fw-bold">Dhaka Tribune</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile2 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile2}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={DhakaTribune}
                    download="DhakaTribune">
                    Download
                </a> <br />
                <span class="fs-3 fw-bold">Canvas</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile3 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile3}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={Canvas}
                    download="Canvas">
                    Download
                </a> <br />
                <span class="fs-3 fw-bold">Prothomalo</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile4 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile4}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={ProthomAlo}
                    download="ProthomAlo">
                    Download
                </a> <br /><br />
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile44 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile44}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={ProthomAlo2}
                    download="ProthomAlo2">
                    Download
                </a> <br />
                <span class="fs-3 fw-bold">Kaler Kantho</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile5 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile5}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={Kalerkantha}
                    download=" Kalerkantha">
                    Download
                </a> <br /><br />

                <span class="fs-3 fw-bold">PeoplesTime</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile6 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile6}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={PeoplesTime}
                    download="PeoplesTime">
                    Download
                </a> <br /><br />

                <div className="row ">
                    <div className="col">
                        {defaultPdfFile7 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile7}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={VideoGame}
                    download="বাংলাদেশের-প্রথম-কম্পিউটার-ভিডিও-গেম-সিরিজ-হাতিরঝিল">
                    Download
                </a> <br />
                <span class="fs-3 fw-bold">Resource</span><br></br>
                <div className="row ">
                    <div className="col">
                        {defaultPdfFile8 && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={defaultPdfFile8}
                                plugins={[defaultLayoutPluginInstance]} />
                        </Worker></>}
                    </div>
                </div> <br></br>
                <a class="btn btn-danger" role="button" href={HiFiPublic}
                    download="News-Hatirjheel-Dream-Begins_-Not-Just-A-Game-_-HiFi-Publi">
                    Download
                </a> <br />

            </div>
        </>
    )
}

export default HatirjheelDreamBegins