import React from 'react'
import "./Footer.css";
import { Link } from "react-router-dom";

export default function
    () {
    return (
        <>
            <section class="footer ">
                <div class="container">
                    <div class="row pt-3">
                        <div class="col-lg-3  col-sm-6 d-flex justify-content-lg-start justify-content-md-start  ">
                            <ul class="list-unstyled" >
                                <li><Link href="#" class="fw-bolder fs-5 ">Products</Link></li>
                                <li><Link to="/products/health-net">Health Net</Link></li>
                                <li><Link to="/products/food-net">Food Net</Link></li>
                                <li><Link to="/products/edu-net">Edu Net</Link></li>
                                <li><Link to="/products/game">Games, VR & Animation</Link>  </li>
                            </ul>
                        </div>
                        <div class="col-lg-3  col-sm-6 d-flex justify-content-lg-center justify-content-md-start">
                            <ul class="list-unstyled" >
                                <li><Link href="#" class="fw-bolder fs-5 ">Company</Link></li>
                                <li><Link to="/about/our-history">Our History</Link></li>
                                <li><Link to="/brain-engineering">Brain Engineering</Link></li>
                                <li><Link to="/corporate-training/who-we-are">Who We Are</Link>  </li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-sm-6 pb-xs-5 d-flex justify-content-lg-center justify-content-md-start ">
                            <ul class="list-unstyled">
                                <li><Link to="/" class="fw-bolder fs-5">Home</Link></li>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/Games/games">Games</Link></li>
                                <li><Link href="#">Contact</Link>  </li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-sm-6 pb-xs-5 d-flex justify-content-lg-end justify-content-md-start ">
                            <ul class="list-unstyled ">
                                <li><Link href="#" class="fw-bolder fs-5">Contact Us</Link></li>
                                <Link href="https://m.facebook.com/profile.php?id=2174307019524563" target="_blank">
                                    <i class="fa fa-facebook-square mt-1 me-3 fs-2"></i></Link>

                                <Link href="https://bd.linkedin.com/in/mahabub-alam-054237a" target="_blank">
                                    <i class="fa fa-linkedin me-3 fs-2"></i></Link>

                                <Link href="https://www.youtube.com/channel/UCB1iHwC-TMh-2Q-uoENNO4A" target="_blank">
                                    <i class="fa fa-youtube fs-2"></i></Link>

                                <li> <Link href="#"> <i class="fa-solid fa-phone mt-2"></i> +880-1909146428 </Link></li>
                                <li> <a href="https://mail.google.com/">mahabubmassive@gmail.com</a></li>
                                {/* <i class="fas fa-envelope-open mt-2"></i> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
