import React from 'react'
import Images from "../../../Assets/Images/index";

function WhoWeAre() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br /> <br /> <br />
                        <h2 className="text-center mt-2" style={{ color: "#FE7E00" }}> Who We Are</h2>
                        <img src={Images.Brain2} alt=' image' class="img-fluid  w-100" />
                        <p class="fs-5 text-justify">
                            <span class="fst-italic">MassiveStars vision is to innovate, create, evolves and delivers the most dream based and emotionally engaging games in the world.</span><br></br>
                            MassiveStar Studio develops high-quality PC, mobile and online games focused on rich stories based on history, renowned character and write-up from famous writer. MassiveStar started the journey in the year 2013 with a group of young talents under a very strong and experienced leadership. MSS is a true combination of experienced leadership and a group of talented young those who are dedicated and fully motivated with a massive dream. MassiveStar is a sister concern of ElevenPlus and MassivePlus.<br></br>
                            Currently announced projects at MassiveStar is Hatirjheel, the first Bangladeshi computer video game is going make history of the country. We think Hatirjheel is the prime gift to the new generation and gift to the people of Bangladesh. We feel that the massive development of Bangladesh what so far have done must be projected to the new generation who have deep interest in information technology and dream for future Bangladesh.<br></br>
                            The present government has started journey with the slogan, “Digital Bangladesh”. A huge development has been done already. Time has come to project the same. With the objective we have developed the game on Hatirjheel, where our local culture, heritage and dream have been reflected. By playing the game our new generation will look at Hatirjheel as the symbol of present and future development of Bangladesh.<br></br>
                            “Hatirjheel, Dream Begins” will be the first computer series game from an organized group in the country and developed by our local engineers and young talents for the people of Bangladesh.<br></br>
                            We are looking at a dream, that day is not so far when Bangladesh may be presented to the new generation of the world as a massive computer games developing country. With the objective we have started two projects:<br></br><br></br>
                            <span class="fw-bold text-break">Projects:</span><br></br>
                            <ul> <li> “Education through Gaming” where our new generation will be able to know our true history, liberation war, our heritage and culture. At the same time another two games will be developed based on (i) Mathematics & Science and (ii) IT & Game development.</li><br></br>
                                <li>“We Make Games” is an initiative to explore the possibilities of development of computer games among young minds studying in different level at schools, colleges and universities. The objective of the program is to transform the computer games-player to games-developer that makes the young generation future focused, responsible leaders, IT savvy, international citizen and finally experienced technology professionals and entrepreneur. In “We Make Games” program 40,000 students from 200 selected schools and colleges will join throughout the country.</li>
                            </ul><br></br>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhoWeAre;